import {
  GroupsApi,
  GroupsApiAddUserToGroupRequest,
  GroupsApiCreateGroupRequest,
  GroupsApiDeleteGroupRequest,
  GroupsApiGetGroupRequest,
  GroupsApiIsUserInGroupRequest,
  GroupsApiListGroupsRequest,
  GroupsApiListUserGroupsRequest,
  GroupsApiListUsersInGroupRequest,
  GroupsApiRemoveUserFromGroupRequest,
  GroupsApiUpdateGroupRequest,
} from "@cloudentity/acp-identity";

import { identityApiClazz } from "./admin-services.common";

const api: () => GroupsApi = identityApiClazz(GroupsApi);

const adminGroupsApi = {
  listGroups: (req: GroupsApiListGroupsRequest) => api().listGroups(req),
  getGroup: (req: GroupsApiGetGroupRequest) => api().getGroup(req),
  createGroup: (req: GroupsApiCreateGroupRequest) => api().createGroup(req),
  updateGroup: (req: GroupsApiUpdateGroupRequest) => api().updateGroup(req),
  deleteGroup: (req: GroupsApiDeleteGroupRequest) => api().deleteGroup(req),

  addUserToGroup: (req: GroupsApiAddUserToGroupRequest) => api().addUserToGroup(req),
  isUserInGroup: (req: GroupsApiIsUserInGroupRequest) => api().isUserInGroup(req),
  listUserGroups: (req: GroupsApiListUserGroupsRequest) => api().listUserGroups(req),
  listUsersInGroup: (req: GroupsApiListUsersInGroupRequest) => api().listUsersInGroup(req),
  removeUserFromGroup: (req: GroupsApiRemoveUserFromGroupRequest) => api().removeUserFromGroup(req),
};

export default adminGroupsApi;
