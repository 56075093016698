import React, { useState } from "react";

import { Tenant, TenantTranslationsSettings } from "@cloudentity/acp-admin";

import Switch from "../../../common/components/Switch";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../common/components/notifications/notificationService";
import adminTranslationsApi from "../../services/adminTranslationsApi";
import { useTranslationMutation } from "../../services/adminTranslationsMutations";
import Tooltip from "../common/Tooltip";

interface Props {
  checked: boolean | undefined;
  isBuiltIn: boolean | undefined;
  isOverwritten: boolean | undefined;
  locale: string;
  translationSettings: TenantTranslationsSettings;
  handleUpdateSettings?: (settings: TenantTranslationsSettings) => Promise<Tenant>;
}

export default function TranslationSwitch({
  checked,
  locale,
  isBuiltIn,
  isOverwritten,
  translationSettings,
  handleUpdateSettings,
}: Props) {
  const [progress, setProgress] = useState(false);
  const translationMutation = useTranslationMutation({ locale });
  const switchDisabled = !isBuiltIn && translationSettings.disabled_translations?.includes(locale);

  function handleEnable(locale: string, isBuiltin: boolean | undefined) {
    setProgress(true);

    if (isBuiltin && handleUpdateSettings) {
      const disabledTranslations = translationSettings.disabled_translations ?? [];
      const isEnabled = !disabledTranslations.includes(locale);

      handleUpdateSettings({
        disabled_translations: isEnabled
          ? [...disabledTranslations, locale]
          : disabledTranslations.filter(v => v !== locale),
      })
        .then(() => {
          if (isEnabled && isOverwritten) {
            return adminTranslationsApi.getTranslation({ locale }).then(res =>
              translationMutation.mutateAsync({
                locale,
                translation: {
                  ...res.data,
                  enabled: false,
                },
              })
            );
          }
        })
        .then(() => notifySuccess(!isEnabled ? "Translation enabled" : "Translation disabled"))
        .finally(() => setProgress(false));
    } else {
      let isEnabled: boolean;

      adminTranslationsApi.getTranslation({ locale }).then(res => {
        isEnabled = !res.data.enabled;
        translationMutation
          .mutateAsync({
            locale,
            translation: {
              ...res.data,
              enabled: !res.data.enabled,
            },
          })
          .then(() => notifySuccess(isEnabled ? "Translation enabled" : "Translation disabled"))
          .catch(notifyErrorOrDefaultTo("Error occurred while trying to save translation"))
          .finally(() => setProgress(false));
      });
    }
  }

  return (
    <Tooltip
      title={
        switchDisabled
          ? "This language is disabled, to enable custom translation, enable corresponding built-in language"
          : undefined
      }
    >
      <div style={{ width: "min-content" }}>
        <Switch
          containerStyle={{ justifyContent: "flex-start" }}
          checked={checked}
          disabled={progress || switchDisabled}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            handleEnable(locale, isBuiltIn);
          }}
        />
      </div>
    </Tooltip>
  );
}
