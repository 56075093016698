import React, { useState } from "react";
import { useNavigate } from "react-router";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

import { Tenant, Translation, TenantTranslationsSettings } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../common/api/paths";
import Fab from "../../../common/components/Fab";
import { getFormattedDate } from "../../../common/utils/date.utils";
import { useGetTenant } from "../../services/adminTenantsQuery";
import EnhancedTable from "../common/EnhancedTable";
import SearchInput from "../common/SearchInput";
import Tooltip from "../common/Tooltip";
import AddTranslationDialog from "./AddTranslationDialog";
import TranslationSwitch from "./TranslationSwitch";
import TranslationsListRowMenu from "./TranslationsListRowMenu";
import { acceptedLanguageObjects } from "./utils";

const useStyles = makeStyles()(theme => ({
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 32,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
    gap: 8,
  },
  chipGreen: {
    color: theme.custom.sa.complimentary.green.high,
    borderColor: theme.custom.sa.complimentary.green.high,
  },
  chipRed: {
    color: theme.custom.sa.complimentary.red.high,
    borderColor: theme.custom.sa.complimentary.red.high,
  },
  noHover: {
    cursor: "default !important",
  },
  caption: {
    color: theme.palette.secondary.light,
  },
}));

const headCells = [
  { id: "language", label: "Language", align: "left" },
  { id: "info", label: "Type", align: "left" },
  { id: "enabled", label: "Enabled", align: "left" },
  { id: "created_at", label: "Date created", align: "left" },
  { id: "updated_at", label: "Date updated", align: "left" },
  { id: "action", label: "", align: "right" },
];

interface Props {
  translations: Translation[];
  handleUpdateSettings: (settings: TenantTranslationsSettings) => Promise<Tenant>;
}

export default function TranslationsList({ translations, handleUpdateSettings }: Props) {
  const { cx, classes: componentClasses } = useStyles();
  const navigate = useNavigate();

  const getTenantQuery = useGetTenant(getTenantId());
  const translationSettings = getTenantQuery.data?.settings?.translations ?? {};

  const [searchText, setSearchText] = useState("");
  const [createDialog, setCreateDialog] = useState(false);

  const [rowMenu, setRowMenu] = useState<{ anchorEl: HTMLElement; row: Translation }>();

  const filteredDefaultTranslations = translations
    .filter(v => v.locale?.toLowerCase().includes(searchText.toLowerCase()))
    .sort((a, b) => {
      if (!a.is_built_in && b.is_built_in) return 1;
      if (a.is_built_in && !b.is_built_in) return -1;
      return a.locale.localeCompare(b.locale);
    });

  function goToTranslation(translation: Translation) {
    navigate(`/appearance/translations/${translation.locale}`);
  }

  return (
    <>
      <div className={componentClasses.toolbar}>
        <SearchInput
          value={searchText}
          placeholder="Search"
          onSearch={setSearchText}
          onClear={() => setSearchText("")}
        />

        {/* has permissions */}
        <Fab size="large" onClick={() => setCreateDialog(true)}>
          + Create language
        </Fab>
      </div>

      <EnhancedTable
        id="translations"
        data={filteredDefaultTranslations}
        headCells={headCells}
        createRow={(row: Translation, i, classes) => {
          const name = acceptedLanguageObjects.find(t => t.code === row.locale)?.name ?? row.locale;
          const isBuiltIn = row.is_built_in;
          const isOverwritten =
            isBuiltIn && translations.some(t => !t.is_built_in && t.locale === row.locale);
          const enabled = isBuiltIn
            ? !translationSettings.disabled_translations?.includes(row.locale)
            : row.enabled;

          return (
            <TableRow
              key={i}
              hover={!isBuiltIn}
              style={{ height: 57, position: "relative" }}
              onClick={!isBuiltIn ? () => goToTranslation(row) : undefined}
              className={cx([
                classes.rowWithHoverIcons,
                classes.row,
                isBuiltIn && componentClasses.noHover,
              ])}
            >
              <TableCell>
                {name}
                <Typography variant="caption" display="block" className={componentClasses.caption}>
                  {row.locale}
                </Typography>
              </TableCell>
              <TableCell>
                <div className={componentClasses.chips}>
                  {isBuiltIn ? <InfoChip label="Built-in" /> : <InfoChip label="Custom" />}
                </div>
              </TableCell>

              <TableCell>
                <TranslationSwitch
                  checked={enabled}
                  isBuiltIn={isBuiltIn}
                  isOverwritten={isOverwritten}
                  locale={row.locale}
                  translationSettings={translationSettings}
                  handleUpdateSettings={handleUpdateSettings}
                />
              </TableCell>

              <TableCell>
                <Tooltip title={new Date(row.created_at ?? "").toLocaleString()}>
                  <span>{getFormattedDate(row.created_at ?? "")}</span>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip title={new Date(row.updated_at ?? "").toLocaleString()}>
                  <span>{getFormattedDate(row.updated_at ?? "")}</span>
                </Tooltip>
              </TableCell>
              <TableCell align="right" padding="none" style={{ padding: "0 5px" }}>
                {!isOverwritten && (
                  <IconButton
                    onClick={e => {
                      e.stopPropagation();
                      setRowMenu({ anchorEl: e.currentTarget, row });
                    }}
                    aria-label="more"
                    size="large"
                  >
                    <MoreVertIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          );
        }}
      />

      {createDialog && (
        <AddTranslationDialog onCancel={() => setCreateDialog(false)} translations={translations} />
      )}

      {rowMenu && (
        <TranslationsListRowMenu
          rowMenu={rowMenu}
          onClose={() => setRowMenu(undefined)}
          translations={translations}
        />
      )}
    </>
  );
}

function InfoChip({ label, color }: { label: string; color?: "green" | "red" }) {
  const { cx, classes } = useStyles();

  return (
    <Chip
      label={label}
      variant="outlined"
      size="small"
      className={cx(color === "green" && classes.chipGreen, color === "red" && classes.chipRed)}
    />
  );
}
