import React, { useMemo, useState } from "react";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

import { ExtendedUser, ExtendedUserStatusEnum } from "@cloudentity/acp-identity";

import { getTenantId } from "../../../../common/api/paths";
import RouterLink from "../../../../common/components/RouterLink";
import AdvancedTable from "../../../../common/components/table/AdvancedTable";
import { AdvancedTableData } from "../../../../common/components/table/types";
import { useListIDPs } from "../../../services/adminIDPsQuery";
import { useCheckPoolPermissions, useListPools } from "../../../services/adminIdentityPoolsQuery";
import { convertUserWithDataToExtendedUser } from "../../../services/adminIdentityUsersQuery";
import PageContent from "../../common/PageContent";
import { BUILD_IN_ADMIN_POOL_ID } from "./AdministratorManagement";
import AdministratorUserEdit from "./AdministratorUserEdit";
import AdministratorUsersCreateNew from "./AdministratorUsersCreateNew";
import AdministratorUsersFilter from "./AdministratorUsersFilter";
import AdministratorUsersRowMenu from "./AdministratorUsersRowMenu";
import { useAdministratorUsersColumns } from "./useAdministratorUsersColumns";

export const useStyles = makeStyles()(theme => ({
  toolBar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 32,
  },
  toolBarRight: {
    display: "flex",
    alignItems: "center",
  },
  editButton: {
    marginRight: 16,
    border: `1px solid ${theme.custom.greys.border}`,
    padding: 14,
  },
}));

const mapColumnsConfigurationFn = col => {
  if (col.id === "roles") {
    return false;
  }

  if (col.id === "given_name") {
    return {
      ...col,
      header: "First Name",
    };
  }

  if (col.id === "family_name") {
    return {
      ...col,
      header: "Last Name",
    };
  }

  if (col.id === "identifiers") {
    return {
      ...col,
      header: "Identifier",
    };
  }

  return col;
};

const mapDefaultColumnsIdsFn = col =>
  ["status_updated_at", "addresses"].includes(col) ? false : col;

interface Props {
  workspaceId: string;
  identityPoolID: string;
  usersWithPagination: AdvancedTableData<ExtendedUser>;
}

export default function AdministratorUsersTable({
  workspaceId,
  identityPoolID,
  usersWithPagination,
}: Props) {
  const { classes } = useStyles();

  const [editUser, setEditUser] = useState<{
    userID: string;
  } | null>(null);

  const [moreMenu, setMoreMenu] = useState<{
    anchorEl: HTMLElement;
    user: ExtendedUser;
  }>();

  const {
    columnsConfiguration,
    defaultColumnsIds,
    currentColumnsIds,
    onSetCurrentColumnsIds,
    isResolved,
  } = useAdministratorUsersColumns({
    identityPoolID,
    workspaceID: workspaceId,
    mapColumnsConfigurationFn,
    mapDefaultColumnsIdsFn,
  });

  const checkPoolPermissionsQuery = useCheckPoolPermissions(identityPoolID);
  const hasManageUserPermission = checkPoolPermissionsQuery.data?.manage_identity_pool_users;

  return (
    <PageContent progress={!isResolved}>
      <ConnectedPoolsToAdminWorkspaceAlert />

      <div className={classes.toolBar}>
        {usersWithPagination.onSetQuery && (
          <AdministratorUsersFilter onQuery={usersWithPagination.onSetQuery} />
        )}

        <div className={classes.toolBarRight}>
          <AdministratorUsersCreateNew
            workspaceId={workspaceId}
            identityPoolID={identityPoolID}
            onCreated={user => {
              usersWithPagination.addCreatedUser &&
                usersWithPagination.addCreatedUser(convertUserWithDataToExtendedUser(user));
            }}
          />
        </div>
      </div>

      <AdvancedTable<ExtendedUser>
        tableId="administrator-management-users"
        columns={columnsConfiguration}
        activeColumns={currentColumnsIds}
        initialActiveColumns={defaultColumnsIds}
        setActiveColumns={columns => {
          onSetCurrentColumnsIds(columns);
        }}
        onRowClick={
          hasManageUserPermission
            ? (e, rowId) => {
                e.preventDefault();
                setEditUser({ userID: rowId! });
              }
            : undefined
        }
        onMoreIconClick={(e, user) => {
          setMoreMenu({ anchorEl: e.currentTarget, user });
        }}
        disabledRow={row => row.status === ExtendedUserStatusEnum.Deleted}
        data={usersWithPagination}
      />

      {editUser && (
        <AdministratorUserEdit
          workspaceId={workspaceId}
          poolId={identityPoolID}
          userId={editUser.userID}
          onClose={() => {
            setEditUser(null);
          }}
        />
      )}

      {moreMenu && (
        <AdministratorUsersRowMenu
          anchorEl={moreMenu?.anchorEl}
          onClose={() => setMoreMenu(undefined)}
          identityPoolId={moreMenu?.user?.user_pool_id}
          userId={moreMenu?.user?.id!}
          workspaceId={workspaceId}
          onEdit={() => setEditUser({ userID: moreMenu?.user?.id! })}
        />
      )}
    </PageContent>
  );
}

export const ConnectedPoolsToAdminWorkspaceAlert = () => {
  const idpsQuery = useListIDPs(getTenantId(), "admin");
  const poolsQuery = useListPools({ tid: getTenantId(), limit: 100 });

  const connectedPoolsToAdminWorkspace = useMemo(() => {
    return (idpsQuery.data?.idps || [])
      .filter(idp => idp.identity_pool_id !== BUILD_IN_ADMIN_POOL_ID)
      .filter(idp => !!idp.identity_pool_id)
      .map(idp => poolsQuery.data?.pools?.find(pool => pool.id === idp.identity_pool_id))
      .filter(pool => !!pool)
      .filter(pool => !pool?.system);
  }, [idpsQuery.data, poolsQuery.data]);

  return connectedPoolsToAdminWorkspace.length > 0 ? (
    <Alert severity="info" style={{ marginBottom: 32 }}>
      <AlertTitle>Admin Identity Pools</AlertTitle>
      <Typography>
        Identity Pools connected to Admin workspace:{" "}
        {connectedPoolsToAdminWorkspace.map((pool, index) => {
          return (
            <span key={pool?.id}>
              {index > 0 && <span>, </span>}
              <RouterLink to={`/identity-pools/pools/${pool?.id}/users`}>{pool?.name}</RouterLink>
            </span>
          );
        })}
      </Typography>
    </Alert>
  ) : null;
};
