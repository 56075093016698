import React, { lazy, useMemo, useState } from "react";

import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useQueryClient } from "@tanstack/react-query";
import { Mail, User } from "react-feather";
import { makeStyles } from "tss-react/mui";

import {
  NewUserIdentifierTypeEnum,
  NewUserPayloadStatusEnum,
  NewUserVerifiableAddressStatusEnum,
  NewUserVerifiableAddressTypeEnum,
  PoolResponse,
} from "@cloudentity/acp-identity";

import { getTenantId } from "../../../../common/api/paths";
import Button from "../../../../common/components/Button";
import { notifyErrorOrDefaultTo } from "../../../../common/components/notifications/notificationService";
import Form, { useForm } from "../../../../common/utils/forms/Form";
import FormFooter from "../../../../common/utils/forms/FormFooter";
import TextFieldRequired from "../../../../common/utils/forms/TextFieldRequired";
import { trimStringValues } from "../../../../common/utils/object.utils";
import { listWorkspacePoolsQueryKey } from "../../../services/adminIdentityPoolsQuery";
import identityUsersApi from "../../../services/adminIdentityUsersApi";
import { listUsersQueryKey } from "../../../services/adminIdentityUsersQuery";
import { useWorkspace } from "../../common/useWorkspace";
import { SmallInfoText, Steps, useCommonStyles } from "../utils";
import ProgressIndicator from "./ProgressIndicator";

const PasskeyLottie = lazy(() => import("../animations/PasskeyLottie"));

const useStyles = makeStyles()(theme => ({
  container: {
    padding: 32,
    height: "100%",
    width: 650,
    borderRight: "1px solid",
    borderRightColor: theme.custom.sa.neutrals.n20,
  },
  content: {
    position: "relative",
    marginBottom: 32,
  },
  clearButton: {
    marginLeft: -8,
    fontSize: 12,
  },
  right: {
    flex: 1,
    backgroundColor: "#e0f9c7",
  },
}));

interface Props {
  pool: PoolResponse;
  email: string;
  onChangeStep: (step: Steps) => void;
}

export default function RegisterUser({ pool, email, onChangeStep }: Props) {
  const { classes: commonClasses } = useCommonStyles();
  const { classes } = useStyles();
  const [workspace] = useWorkspace();
  const queryClient = useQueryClient();
  const [progress, setProgress] = useState(false);
  const initialData = useMemo(() => ({ email, first_name: "Bob", last_name: "Robinson" }), [email]);

  const form = useForm({
    id: "get-started-invite-user",
    initialValues: initialData,
    progress,
  });

  const handleSendActivationMessage = (userId: string) => {
    identityUsersApi
      .sendActivationMessage({
        ipID: pool?.id ?? "",
        userID: userId,
        serverId: workspace,
      })
      .catch(notifyErrorOrDefaultTo("Error occurred while trying to send invitation message"));
  };

  const handleCreate = () => {
    if (!pool) return;

    const data = form.getValues();
    setProgress(true);

    const selectedIdentifier = data.email?.trim();
    const credentials = [];
    const payload = trimStringValues({
      family_name: data.first_name,
      given_name: data.last_name,
      name: `${data.first_name} ${data.last_name}`,
    });

    return identityUsersApi
      .createUser({
        ipID: pool?.id ?? "",
        newUser: {
          payload,
          metadata: undefined,
          business_metadata: undefined,
          status: NewUserPayloadStatusEnum.New,
          credentials,
          identifiers: [
            {
              identifier: selectedIdentifier,
              type: NewUserIdentifierTypeEnum.Email,
            },
          ],
          verifiable_addresses: [
            {
              address: selectedIdentifier,
              status: NewUserVerifiableAddressStatusEnum.Active,
              type: NewUserVerifiableAddressTypeEnum.Email,
              verified: false,
            },
          ],
        },
      })
      .then(res => {
        if (res.data.id) {
          handleSendActivationMessage(res.data.id);
        }
      })
      .then(() =>
        queryClient.invalidateQueries({ queryKey: listUsersQueryKey(getTenantId(), pool?.id) })
      )
      .then(() => {
        queryClient.invalidateQueries({ queryKey: listWorkspacePoolsQueryKey(workspace) });
      })
      .then(() => {
        onChangeStep(Steps.MAIN_USE_CASE_EXPLORE);
      })
      .catch(err => {
        if (err.response.status === 409) {
          return form.setError(
            "email",
            {
              message: "User address with given value already exists",
            },
            { shouldFocus: true }
          );
        }
        return notifyErrorOrDefaultTo("Error occurred while trying to invite user")(err);
      })
      .finally(() => setProgress(false));
  };

  return (
    <>
      <div className={commonClasses.leftWithPreview}>
        <div className={classes.container}>
          <Form form={form}>
            <div className={commonClasses.intro}>
              <Typography
                variant="h2"
                className={commonClasses.header}
                id="onboarding-register-user-header"
              >
                Register a user
              </Typography>
              <SmallInfoText fontSize={14}>You can invite additional users later</SmallInfoText>
              <ProgressIndicator value={66} text="2" caption="Out of 3" />

              <Divider className={commonClasses.divider} />
            </div>

            <div className={classes.content}>
              <Typography variant="h5">Invite your customer user</Typography>
              <SmallInfoText>
                We have pre-filled your email information for a sample customer user, allowing you
                to experience the passwordless passkey login as a customer accessing the Blixit Demo
                Application.
              </SmallInfoText>
              <div style={{ margin: "16px 0" }}>
                <Typography
                  variant="textSM"
                  className={commonClasses.recommendedChip}
                  style={{ top: 0 }}
                >
                  RECOMMENDED
                </Typography>
              </div>

              <TextFieldRequired name="email" label="Email" withStartAdornment={<Mail />} />
              <TextFieldRequired
                name="first_name"
                label="First name"
                withStartAdornment={<User />}
                helperText="Your customer first name"
              />
              <TextFieldRequired
                name="last_name"
                label="Last name"
                withStartAdornment={<User />}
                helperText="Your customer last name"
              />
              <Button
                variant="text"
                className={classes.clearButton}
                onClick={() => form.reset({ email: "", first_name: "", last_name: "" })}
              >
                Clear above
              </Button>
            </div>

            <FormFooter
              onCancel={() => onChangeStep(Steps.MAIN_USE_CASE_SIGN_IN)}
              onSubmit={handleCreate}
              submitText="Continue"
              cancelText="Back"
              style={{ display: "flex", justifyContent: "space-between" }}
              cancelButtonProps={{
                color: "inherit",
                size: "medium",
                className: commonClasses.backButton,
              }}
              submitButtonProps={{ size: "medium" }}
            />
          </Form>
        </div>
      </div>
      <div className={classes.right}>
        <PasskeyLottie />
      </div>
    </>
  );
}
