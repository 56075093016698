import React from "react";

import { Group } from "@cloudentity/acp-identity";

import Dialog from "../../../../../../common/components/Dialog";
import Form, { useForm } from "../../../../../../common/utils/forms/Form";
import FormFooter from "../../../../../../common/utils/forms/FormFooter";
import TextField from "../../../../../../common/utils/forms/TextField";
import TextFieldRequired from "../../../../../../common/utils/forms/TextFieldRequired";
import { useUpdateGroupMutation } from "../../../../../services/adminGroupsMutations";

export default function EditGroupDialog({ group, onClose }: { group: Group; onClose: () => void }) {
  const updateGroupMutation = useUpdateGroupMutation({ poolId: group.user_pool_id });

  const form = useForm({
    id: "edit-group",
    progress: updateGroupMutation.isPending,
    initialValues: group,
    mode: "onTouched",
  });

  const handleUpdate = data => {
    updateGroupMutation
      .mutateAsync({
        groupID: group.id!,
        ipID: group.user_pool_id,
        updateGroup: {
          ...group,
          name: data.name,
          description: data.description,
        },
      })
      .then(() => onClose());
  };

  return (
    <Dialog
      open
      onClose={onClose}
      title="Edit Group"
      id="edit-identity-pool-group-dialog"
      fullWidth
      maxWidth="md"
    >
      <Form form={form} noFormTag>
        <TextFieldRequired name="name" label="Name" placeholder="eg. Technical Admins" />
        <TextField
          name="description"
          label="Description"
          placeholder="eg. All technical staff who supportive to manage product operations"
        />

        <FormFooter onCancel={onClose} onSubmit={handleUpdate} submitText="Save" />
      </Form>
    </Dialog>
  );
}
