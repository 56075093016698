import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router";

import Typography from "@mui/material/Typography";
import { useQueryClient } from "@tanstack/react-query";

import { Translation } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../common/api/paths";
import Dialog from "../../../common/components/Dialog";
import { notifyErrorOrDefaultTo } from "../../../common/components/notifications/notificationService";
import AutocompleteField from "../../../common/utils/forms/AutocompleteField";
import Form, { useForm } from "../../../common/utils/forms/Form";
import FormFooter from "../../../common/utils/forms/FormFooter";
import adminTranslationsApi from "../../services/adminTranslationsApi";
import { listTranslationsQueryKey } from "../../services/adminTranslationsQuery";
import { acceptedLanguageObjects } from "./utils";

const initialFormData = {
  locale: "",
};

interface Props {
  onCancel: () => void;
  translations: Translation[];
}

export default function AddTranslationDialog({ onCancel, translations }: Props) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [createProgress, setCreateProgress] = useState(false);

  const form = useForm({
    id: "translartion-add",
    initialValues: initialFormData,
    progress: createProgress,
  });

  const options = useMemo(
    () =>
      acceptedLanguageObjects
        .filter(({ code }) => !translations.find(t => t.locale === code && !t.is_built_in))
        .map(({ code, name }) => ({
          name: code,
          description: name,
        })),
    [translations]
  );

  function handleCreate(data) {
    setCreateProgress(true);

    return adminTranslationsApi
      .upsertTranslation({ locale: data.locale.name })
      .then(() =>
        queryClient.invalidateQueries({ queryKey: listTranslationsQueryKey(getTenantId()) })
      )
      .then(onCancel)
      .then(() => navigate(`/appearance/translations/${data.locale.name}`))
      .catch(notifyErrorOrDefaultTo("Error occurred while trying to create language"))
      .finally(() => {
        setCreateProgress(false);
      });
  }

  return (
    <Dialog onClose={onCancel} id="add-translation-dialog" title="Add translation">
      <Form form={form}>
        <AutocompleteField
          name="locale"
          label="Language"
          options={options}
          rules={{ required: "Method is required" }}
          filterSelectedOptions
          filterOptions={(options, state) => {
            const search = state.inputValue.toLowerCase();
            return options.filter(
              option =>
                option.name.toLowerCase().includes(search) ||
                option.description.toLowerCase().includes(search)
            );
          }}
          renderOption={({ key, ...props }, option, params) => (
            <li
              key={key}
              {...props}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              {option.name}

              <Typography variant="caption">{option.description}</Typography>
            </li>
          )}
          getOptionLabel={option => (typeof option === "object" ? option.name : option)}
        />

        <FormFooter onSubmit={handleCreate} onCancel={onCancel} submitText="Create" />
      </Form>
    </Dialog>
  );
}
