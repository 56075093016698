import React, { useState } from "react";

import Button from "@mui/material/Button";
import { Plus } from "react-feather";

import Fab from "../../../../../../common/components/Fab";
import { useCheckPoolPermissions } from "../../../../../services/adminIdentityPoolsQuery";
import CreateGroupDialog from "./CreateGroupDialog";

interface Props {
  identityPoolID: string;
}

export default function CreateGroupButton({ identityPoolID }: Props) {
  const [createGroup, setCreateGroup] = useState(false);

  const checkPoolPermissionsQuery = useCheckPoolPermissions(identityPoolID);

  if (!checkPoolPermissionsQuery.data?.manage_identity_pool_groups) {
    return null;
  }

  return (
    <>
      <Fab onClick={() => setCreateGroup(true)} id="table-toolbar-action-button">
        <Plus size={14} style={{ marginRight: 8 }} />
        CREATE GROUP
      </Fab>

      {createGroup && (
        <CreateGroupDialog
          poolId={identityPoolID}
          onClose={() => {
            setCreateGroup(false);
          }}
        />
      )}
    </>
  );
}
