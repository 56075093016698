import React, { useMemo, useState } from "react";

import { loader } from "@monaco-editor/react";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useQueryClient } from "@tanstack/react-query";

import { Tenant, TenantTranslationsSettings, Translation } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../common/api/paths";
import RouteLeavingGuard from "../../../common/components/RouteLeavingGuard";
import AutocompleteField from "../../../common/utils/forms/AutocompleteField";
import CheckboxField from "../../../common/utils/forms/CheckboxField";
import Form, { useForm } from "../../../common/utils/forms/Form";
import FormFooter from "../../../common/utils/forms/FormFooter";
import { useGetTenant } from "../../services/adminTenantsQuery";
import { acceptedLanguageObjects } from "./utils";

loader.config({
  paths: {
    vs: `${document.baseURI}assets/monaco/min/vs`,
  },
});

interface Props {
  translations: Translation[];
  handleUpdate(settings: TenantTranslationsSettings): Promise<Tenant>;
}

export default function TranslationsSettings({ translations, handleUpdate }: Props) {
  const queryClient = useQueryClient();

  const [updateProgress, setUpdateProgress] = useState(false);

  const getTenantQuery = useGetTenant(getTenantId());

  const initialData = useMemo(
    () => getTenantQuery.data.settings?.translations ?? {},
    [getTenantQuery.data]
  );

  const form = useForm({
    id: "translations-settings",
    initialValues: initialData,
    progress: updateProgress,
  });

  function handleSave(data) {
    setUpdateProgress(true);
    handleUpdate(data).finally(() => setUpdateProgress(false));
  }

  const options = useMemo(
    () =>
      acceptedLanguageObjects
        .filter(({ code }) => translations.find(t => t.locale === code))
        .map(v => v.code),
    [translations]
  );

  return (
    <>
      {getTenantQuery.isLoading ? (
        <LinearProgress
          style={{ position: "absolute", top: 0, left: 0, width: "100%", zIndex: 1000 }}
        />
      ) : (
        <>
          <Form form={form}>
            <Grid container>
              <Grid item xs={12} lg={7}>
                <Paper style={{ padding: 32 }}>
                  <CheckboxField
                    name="enabled"
                    label="Enable translations"
                    style={{ marginBottom: 16 }}
                  />

                  <CheckboxField
                    name="selector_enabled"
                    label="Enable language selector"
                    style={{ marginBottom: 24 }}
                  />

                  <AutocompleteField
                    name="default_locale"
                    label="Default language"
                    options={options}
                    rules={{ required: "Language is required" }}
                    filterSelectedOptions
                    renderOption={({ key, ...props }, option) => {
                      const description = acceptedLanguageObjects.find(
                        v => v.code === option
                      )?.name;
                      return (
                        <li
                          key={key}
                          {...props}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          {option}

                          <Typography variant="caption">{description}</Typography>
                        </li>
                      );
                    }}
                  />

                  <FormFooter onSubmit={handleSave} />
                </Paper>
              </Grid>
            </Grid>
            <RouteLeavingGuard />
          </Form>
        </>
      )}
    </>
  );
}
