import React, { useState } from "react";

import { Group } from "@cloudentity/acp-identity";

import Menu from "../../../../../../common/components/Menu";
import DeleteGroupDialog from "./DeleteGroupDialog";
import EditGroupDialog from "./EditGroupDialog";

interface Props {
  group?: Group;
  anchorEl?: HTMLElement;
  onClose: () => void;
}

export default function GroupsRowMenu({ group, anchorEl, onClose }: Props) {
  const [editGroupDialog, setEditGroupDialog] = useState(false);
  const [deleteGroupDialog, setDeleteGroupDialog] = useState(false);

  return (
    <>
      <Menu
        items={[
          // { // TODO: implement
          //   onClick: () => {},
          //   label: "Open",
          //   id: "open",
          // },
          // {
          //   onClick: () => {},
          //   label: "Add nested group",
          //   id: "add-nested-group",
          // },
          {
            onClick: () => {
              setEditGroupDialog(true);
              onClose();
            },
            label: "Edit",
            id: "edit",
          },
          {
            onClick: () => {
              setDeleteGroupDialog(true);
              onClose();
            },
            label: "Delete",
            id: "delete",
          },
        ]}
        anchorEl={anchorEl}
        onClose={onClose}
      />

      {editGroupDialog && group && (
        <EditGroupDialog
          group={group}
          onClose={() => {
            setEditGroupDialog(false);
            onClose();
          }}
        />
      )}

      {deleteGroupDialog && group && (
        <DeleteGroupDialog
          group={group}
          onCancel={() => {
            setDeleteGroupDialog(false);
            onClose();
          }}
        />
      )}
    </>
  );
}
