import { useMemo } from "react";
import { getDependencyArrayRef } from "../tools/getDependencyArrayRef";
import { mergeClasses } from "../mergeClasses";
export const useMuiThemeStyleOverridesPlugin = ({ classes, theme, muiStyleOverridesParams, css, cx, name }) => {
    var _a, _b;
    require_named: {
        // NOTE: Hack for backwards compatibility with the makeStyles API.
        if (name === "makeStyle no name") {
            name = undefined;
            break require_named;
        }
        if (muiStyleOverridesParams !== undefined && name === undefined) {
            throw new Error("To use muiStyleOverridesParams, you must specify a name using .withName('MyComponent')");
        }
    }
    let styleOverrides = undefined;
    try {
        styleOverrides =
            name === undefined
                ? undefined
                : ((_b = (_a = theme.components) === null || _a === void 0 ? void 0 : _a[name /*example*/]) === null || _b === void 0 ? void 0 : _b.styleOverrides) || undefined;
        // eslint-disable-next-line no-empty
    }
    catch (_c) { }
    const classesFromThemeStyleOverrides = useMemo(() => {
        if (styleOverrides === undefined) {
            return undefined;
        }
        const themeClasses = {};
        for (const ruleName in styleOverrides) {
            const cssObjectOrGetCssObject = styleOverrides[ruleName];
            if (!(cssObjectOrGetCssObject instanceof Object)) {
                continue;
            }
            themeClasses[ruleName] = css(typeof cssObjectOrGetCssObject === "function"
                ? cssObjectOrGetCssObject({
                    theme,
                    "ownerState": muiStyleOverridesParams === null || muiStyleOverridesParams === void 0 ? void 0 : muiStyleOverridesParams.ownerState,
                    ...muiStyleOverridesParams === null || muiStyleOverridesParams === void 0 ? void 0 : muiStyleOverridesParams.props
                })
                : cssObjectOrGetCssObject);
        }
        return themeClasses;
    }, [
        styleOverrides,
        getDependencyArrayRef(muiStyleOverridesParams === null || muiStyleOverridesParams === void 0 ? void 0 : muiStyleOverridesParams.props),
        getDependencyArrayRef(muiStyleOverridesParams === null || muiStyleOverridesParams === void 0 ? void 0 : muiStyleOverridesParams.ownerState),
        css
    ]);
    classes = useMemo(() => mergeClasses(classes, classesFromThemeStyleOverrides, cx), [classes, classesFromThemeStyleOverrides, cx]);
    return { classes };
};
