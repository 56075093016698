import React, { useCallback, useState } from "react";

import debounce from "lodash/debounce";

import SearchInput from "../../../../common/SearchInput";

export const escapePercentageSignInValue = value => value.replaceAll("%", "%25");

interface Props {
  onQuery?: (query: any) => void;
}

export default function GroupsFilter({ onQuery }: Props) {
  const [value, setValue] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleUpdate = useCallback(
    debounce((searchText: string) => {
      onQuery &&
        onQuery(
          searchText
            ? decodeURIComponent(
                escapePercentageSignInValue(
                  JSON.stringify({ name: escapePercentageSignInValue(searchText) })
                )
              )
            : undefined
        );
    }, 1000),
    [onQuery]
  );

  const handleChangeSearchText = (newSearchText: string) => {
    setValue(newSearchText);
    handleUpdate(newSearchText);
  };

  const handleClearSearchText = () => {
    setValue("");
    handleUpdate("");
  };

  if (!onQuery) {
    return null;
  }

  return (
    <SearchInput
      value={value}
      placeholder="Search by name"
      fullWidth
      hideStartAdornment
      onSearch={handleChangeSearchText}
      onClear={handleClearSearchText}
    />
  );
}
