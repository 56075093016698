import React, { useState } from "react";

import Typography from "@mui/material/Typography";
import { useQueryClient } from "@tanstack/react-query";

import { IDP } from "@cloudentity/acp-root";

import logo from "../../../../assets/images/logos/sa/sa_rgb_mark_blk.svg";
import { getTenantId } from "../../../../common/api/paths";
import ConfirmationDialog from "../../../../common/components/ConfirmationDialog";
import {
  getFormData,
  handleMarketoFormSubmit,
  MARKETO_ENTERPRISE_IDP_UPGRADE_REQUEST_FORM_ID,
} from "../../../../common/components/upgrade/marketo.utils";
import { BRAND_NAME } from "../../../../common/theme/theme";
import { useGetLicense } from "../../../services/adminLicenseQuery";
import { useGetTenant } from "../../../services/adminTenantsQuery";
import { useGetUserInfo } from "../../../services/oauth2Query";
import { useWorkspace } from "../../common/useWorkspace";
import { providers } from "../../identities/identities.utils";
import CreateIdp from "../../identities/identities_view_tabbed/identitiesListSimple/CreateIdp";
import { handleExistingIdpCheckboxChange } from "../../identities/identities_view_tabbed/identitiesListSimple/IdentitiesListSimple";
import { SmallInfoText, TenantMetadataRegistration } from "../utils";
import SignInStepSwitchCard from "./SignInStepSwitchCard";

interface Props {
  externalIdps: IDP[];
  setOpenUpgradeDialog: (isOpen: boolean) => void;
  setSentUpgradeDialog: (isOpen: boolean) => void;
  setFocusedCardIndex: (focusedCardIndex: number) => void;
}

export default function SignInStepUnlockDialog({
  externalIdps,
  setOpenUpgradeDialog,
  setSentUpgradeDialog,
  setFocusedCardIndex,
}: Props) {
  const [workspace] = useWorkspace();
  const queryClient = useQueryClient();

  const userinfoQuery = useGetUserInfo();
  const tenantQuery = useGetTenant(getTenantId());
  const licenseQuery = useGetLicense();
  const hasEnterpriseIdpsCapability =
    !licenseQuery.data?.enforcement_enabled || licenseQuery.data?.has_enterprise_idps_capability;

  const [createIdp, setCreateIdp] = useState("");
  const [cardProgress, setCardProgress] = useState("");
  const [sentUpgradeProgress, setSentUpgradeProgress] = useState(false);

  const idps = ["google", "github", "azure"];
  const excludedIdps = [
    "cloudentity",
    "static",
    "custom",
    "external",
    "identity_pool",
    "organization",
    "google_embedded",
    "github_embedded",
    "saml",
  ];
  const upgradeIdps = providers
    .map(v => v.method)
    .filter(v => !idps.includes(v) && !excludedIdps.includes(v));

  const formData = getFormData(
    tenantQuery.data?.metadata?.registration as TenantMetadataRegistration,
    userinfoQuery.data
  );

  return (
    <>
      <ConfirmationDialog
        id="idp-upgrade-dialog"
        title={
          !hasEnterpriseIdpsCapability ? (
            <>
              <img src={logo} alt="logo" style={{ width: 32, marginRight: 8 }} /> Upgrade to{" "}
              {BRAND_NAME} CIAM Enterprise Suite
            </>
          ) : (
            "Enterprise Connections"
          )
        }
        onCancel={() => setOpenUpgradeDialog(false)}
        progress={sentUpgradeProgress}
        onConfirm={
          !hasEnterpriseIdpsCapability
            ? () =>
                handleMarketoFormSubmit(
                  MARKETO_ENTERPRISE_IDP_UPGRADE_REQUEST_FORM_ID,
                  formData,
                  setSentUpgradeProgress,
                  () => {
                    setSentUpgradeDialog(true);
                    setOpenUpgradeDialog(false);
                  }
                )
            : undefined
        }
        confirmText="Upgrade to unlock more"
        mode="info"
        content={
          <>
            {!hasEnterpriseIdpsCapability && (
              <>
                <Typography variant="h6">Enterprise Connections</Typography>
                <SmallInfoText>
                  Upgrade to the Enterprise plan to add more connections and unlock an entirely new
                  level of features.
                </SmallInfoText>
              </>
            )}
            <div style={{ marginTop: 16 }}>
              {upgradeIdps.map((idp, i) => {
                const provider = providers.find(provider => provider.method === idp);
                const disabled = !hasEnterpriseIdpsCapability && provider?.type !== "social";
                const found = externalIdps.find(v => v.method === idp);

                return provider ? (
                  <SignInStepSwitchCard
                    key={idp}
                    id={idp}
                    name={provider.name}
                    icon={<img src={provider.icon} alt={idp} />}
                    idpAvatar
                    checked={!!found && !found.disabled}
                    onChange={checked => {
                      if (checked && !found) {
                        setCreateIdp(idp);
                      }

                      setFocusedCardIndex(0);
                      handleExistingIdpCheckboxChange(
                        queryClient,
                        workspace,
                        found?.id ?? "",
                        found,
                        checked,
                        setCardProgress
                      );
                    }}
                    disabled={!!cardProgress || disabled}
                    noMargin
                    hasPrev={i > 0}
                    hasNext={i < upgradeIdps.length - 1}
                  />
                ) : null;
              })}
            </div>
          </>
        }
      />

      {createIdp && (
        <CreateIdp
          method={createIdp}
          workspace={workspace}
          handleClose={() => setCreateIdp("")}
          onCreated={() => {}}
          isHidden={false}
        />
      )}
    </>
  );
}
