import React from "react";

import { PoolAuthenticationMechanismsEnum } from "@cloudentity/acp-identity";

import { getTenantId } from "../../../../../common/api/paths";
import Alert from "../../../../../common/components/Alert";
import FormInputLabel from "../../../../../common/components/FormInputLabel";
import DurationField from "../../../../../common/utils/forms/DurationField";
import { useFormContext } from "../../../../../common/utils/forms/Form";
import SliderField from "../../../../../common/utils/forms/SliderField";
import { validators } from "../../../../../common/utils/forms/validation";
import { useFeature } from "../../../../../common/utils/hooks/useFeature";
import { useGetAuthorizationServer } from "../../../../services/adminServersQuery";
import IdentityPoolsAuthnSelect from "../identityPoolCreate/IdentityPoolsAuthnSelect";

interface Props {
  workspaceId?: string;
  progress: boolean;
  noManagePermission: boolean;
  settings?: {
    [key in PoolAuthenticationMechanismsEnum]?: () => void;
  };
  highlightedItem?: "second_factor_threshold";
}
export default function IdentityPoolAuthenticationMechanisms({
  workspaceId,
  progress,
  noManagePermission,
  settings,
  highlightedItem,
}: Props) {
  const isRiskEngineEnabled = useFeature("risk_engine");
  const { form } = useFormContext();
  const authenticationMechanisms = form.watch("authentication_mechanisms") || [];
  const secondFactorAuthenticationMechanisms =
    form.watch("second_factor_authentication_mechanisms") || [];
  const secondFactorThreshold = form.watch("second_factor_threshold") || 0;

  const getServerQuery = useGetAuthorizationServer(getTenantId(), workspaceId);

  return (
    <>
      <IdentityPoolsAuthnSelect
        id="first-factor-authentication-methods"
        label={
          <FormInputLabel
            label="First-Factor Authentication Methods"
            id="first-factor-authn-method-label"
            tooltip="The Authentication method depends on the level of security required, the type of system or device being accessed, and the user's needs and preferences."
          />
        }
        authenticationMechanismsKeyName="authentication_mechanisms"
        preferredAuthenticationMechanismsKeyName="preferred_authentication_mechanism"
        allowedAuthenticationMechanisms={getServerQuery.data?.authentication_mechanisms || []}
        options={Object.values(PoolAuthenticationMechanismsEnum).filter(
          v =>
            !authenticationMechanisms.includes(v) &&
            !secondFactorAuthenticationMechanisms.includes(v)
        )}
        progress={progress}
        noManagePermission={noManagePermission}
        settings={settings}
        onMoveToOtherFactor={method => {
          form.setValue("second_factor_authentication_mechanisms", [
            ...secondFactorAuthenticationMechanisms,
            method,
          ]);
          form.setValue(
            "authentication_mechanisms",
            authenticationMechanisms.filter(am => am !== method)
          );
        }}
      />

      {authenticationMechanisms.length === 0 && (
        <Alert severity="warning" title="Warning">
          If there are any First-Factor Authentication Methods set users won't be able to log in.
        </Alert>
      )}

      <IdentityPoolsAuthnSelect
        id="second-factor-authentication-methods"
        label={
          <FormInputLabel
            label="Second-Factor Authentication Methods"
            id="Second-factor-authn-method-label"
          />
        }
        authenticationMechanismsKeyName="second_factor_authentication_mechanisms"
        preferredAuthenticationMechanismsKeyName="second_factor_preferred_authentication_mechanism"
        allowedAuthenticationMechanisms={getServerQuery.data?.authentication_mechanisms || []}
        options={Object.values(PoolAuthenticationMechanismsEnum).filter(
          v =>
            !authenticationMechanisms.includes(v) &&
            !secondFactorAuthenticationMechanisms.includes(v)
        )}
        progress={progress}
        noManagePermission={noManagePermission}
        disabled={authenticationMechanisms.length === 0}
        settings={settings}
        onMoveToOtherFactor={method => {
          form.setValue("authentication_mechanisms", [...authenticationMechanisms, method]);
          form.setValue(
            "second_factor_authentication_mechanisms",
            secondFactorAuthenticationMechanisms.filter(am => am !== method)
          );
        }}
      />
      <DurationField
        id="second-factor-mfa-session-ttl"
        name="mfa_session_ttl"
        label="Reduce 2FA verification on same device"
        description={
          <span>
            Users would not be asked again for 2FA for defined period of time on same device. To
            disable set to <code>0s</code>.
          </span>
        }
        rules={{
          validate: {
            inRangeDuration: validators.inRangeDuration({
              label: "",
              min: "0h0m00s",
              max: "722h0m0s",
            }),
          },
        }}
      />

      {isRiskEngineEnabled && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <SliderField
            name="second_factor_threshold"
            title="Level of assurance threshold"
            helperText="Users will be prompted for a second factor if the real-time level of assurance falls below the required threshold."
            withValueIndicator
            sliderProps={{
              value: Number(parseFloat((secondFactorThreshold * 100).toFixed(2))),
              onChange: (_, value) =>
                form.setValue(
                  "second_factor_threshold",
                  Number(((value as number) / 100).toFixed(2))
                ),
              step: 1,
              size: "small",
            }}
            highlighted={highlightedItem === "second_factor_threshold"}
          />
        </div>
      )}
    </>
  );
}
