import React from "react";

import { Group } from "@cloudentity/acp-identity";

import RemoveConfirmationDialog from "../../../../../../common/components/RemoveConfirmationDialog";
import { useDeleteGroupMutation } from "../../../../../services/adminGroupsMutations";

export default function DeleteGroupDialog({
  group,
  onCancel,
}: {
  group: Group;
  onCancel: () => void;
}) {
  const deleteGroupMutation = useDeleteGroupMutation({ poolId: group.user_pool_id });

  const handleDelete = () => {
    deleteGroupMutation
      .mutateAsync({ groupID: group.id!, ipID: group.user_pool_id })
      .then(() => onCancel());
  };

  return (
    <RemoveConfirmationDialog
      title="Delete group"
      onCancel={onCancel}
      onConfirm={handleDelete}
      progress={deleteGroupMutation.isPending}
      name={group.name || ""}
      type="group"
    />
  );
}
