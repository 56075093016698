import React, { useEffect, useState } from "react";

import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { useQueryClient } from "@tanstack/react-query";

import {
  OrganizationResponse,
  Server,
  ServerBindingResponse,
  ServerResponse,
  TenantPermissionsResponse,
  WorkspaceResponse,
  WorkspaceResponseTypeEnum,
} from "@cloudentity/acp-admin";

import emptyState from "../../../assets/images/emptyStates/Workspaces2.svg";
import {
  handleErrorWithNotify,
  notifySuccess,
} from "../../../common/components/notifications/notificationService";
import { useFeature } from "../../../common/utils/hooks/useFeature";
import adminServersApi from "../../services/adminServersApi";
import {
  getAuthorizationServerQueryKey,
  listWorkspacesQueryKey,
} from "../../services/adminServersQuery";
import { useCheckTenantPermissions } from "../../services/adminTenantsQuery";
import CheckTenantPermissions from "../CheckTenantPermissions";
import useWorkspacesSeqOrCursor from "../common/EnhancedTableAsync/useWorkspacesSeqOrCursor";
import PageContainer from "../common/PageContainer";
import PageHeader from "../common/PageHeader";
import TenantBranding from "../customBranding/TenantBranding";
import NavAndContentContainer from "../nav/NavAndContentContainer";
import TenantNavDrawer from "../nav/TenantNavDrawer";
import TopBar from "../nav/TopBar";
import TranslationController from "../translations/TranslationController";
import TranslationsController from "../translations/TranslationsController";
import AdministratorManagement from "./administrator/AdministratorManagement";
import AdministratorSettingsV2 from "./administrator/AdministratorSettingsV2";
import BetaFeatures from "./administrator/BetaFeatures";
import Appearance from "./appearance/Appearance";
import ThemeDetails from "./appearance/ThemeDetails";
import BruteForceProtection from "./bruteForceProtection/BruteForceProtection";
import BruteForceProtectionByType from "./bruteForceProtection/BruteForceProtectionByType";
import IdentityPools from "./identityPools/IdentityPools";
import IdentityPoolForView from "./identityPools/identityPool/IdentityPoolForView";
import IdentityPoolUser from "./identityPools/identityPool/users/user/IdentityPoolUser";
import IdentitySchema from "./identityPools/schemas/IdentitySchema";
import MessageProviders from "./messageProviders/MessageProviders";
import MfaSettings from "./mfaSettings/MfaSettings";
import TenantOrganizations from "./organizations/TenantOrganizations";
import PermissionController from "./permissions/PermissionController";
import PermissionsController from "./permissions/PermissionsController";
import { WorkspaceTab } from "./workspace-directory.common";
import DeveloperPortalsController from "./workspaces/DeveloperPortalsController";
import {
  WorkspacesControllerWithCursor,
  WorkspacesControllerWithSeq,
} from "./workspaces/WorkspacesController";

export const isWorkspacesItemVisible = (
  tenantPermissions: TenantPermissionsResponse,
  workspaces: WorkspaceResponse[]
) => workspaces.length > 0 || (workspaces.length === 0 && tenantPermissions.create_workspace);

export const checkPermissionsToDisplayNavDrawer = (
  tenantPermissions: TenantPermissionsResponse,
  workspaces: WorkspaceResponse[]
) =>
  tenantPermissions.read_brute_force_protection ||
  tenantPermissions.list_identity_pools ||
  tenantPermissions.read_mfa_methods ||
  tenantPermissions.read_permission_systems ||
  tenantPermissions.read_themes ||
  tenantPermissions.create_workspace ||
  isWorkspacesItemVisible(tenantPermissions, workspaces);

interface Props {
  path: string;
  progress: boolean;
  connectProgress: string;
  deleteProgress: string;
  serversBindings: ServerBindingResponse[];
  initWorkspaceTab: WorkspaceTab | undefined;
  onCreateClick: () => void;
  onDelete: (id: string, name: string, type: WorkspaceResponseTypeEnum | undefined) => void;
  onShowSettings: (id: string) => void;
  onSelectServer: (id: string) => void;
  onConnectToExisting: (aid: string, rid: string) => void;
  onCreateDeveloperPortal: ({ aid }: { aid: string }) => void;
  onUnbind: ({ aid, rid }: { aid: string | undefined; rid: string }) => void;
  onBindToTheme: (server: WorkspaceResponse | OrganizationResponse) => void;
  onUnbindTheme: (server: WorkspaceResponse | OrganizationResponse) => void;
}

export default function TenantView({
  path,
  progress,
  connectProgress,
  deleteProgress,
  serversBindings,
  initWorkspaceTab,
  onCreateClick,
  onDelete,
  onSelectServer,
  onConnectToExisting,
  onCreateDeveloperPortal,
  onUnbind,
  onBindToTheme,
  onUnbindTheme,
}: Props) {
  const [workspaceTab, setWorkspaceTab] = useState(initWorkspaceTab);
  const [cardProgress, setCardProgress] = useState("");

  const adminWorkspaceAccessEnabled = useFeature("admin_workspace_access");
  const systemWorkspaceAccessEnabled = useFeature("system_workspace_access");
  const checkTenantPermissionsQuery = useCheckTenantPermissions();
  const listWorkspacesQuery = useWorkspacesSeqOrCursor();

  const isNavDrawerAllowedToDisplay = checkPermissionsToDisplayNavDrawer(
    checkTenantPermissionsQuery.data || {},
    listWorkspacesQuery.totalData ?? []
  );

  const queryClient = useQueryClient();

  const canListWorkspaces = checkTenantPermissionsQuery.data?.list_workspaces;

  const handleBookmarkServer = (server: ServerResponse) => {
    setCardProgress(server.id ?? "");
    adminServersApi
      .updateAuthorizationServer({
        wid: server.id!,
        server: { ...server, enable_quick_access: !server.enable_quick_access } as Server,
      })
      .then(() =>
        Promise.all([
          queryClient.invalidateQueries({
            queryKey: getAuthorizationServerQueryKey(server.tenant_id, server.id),
          }),
          queryClient.invalidateQueries({ queryKey: listWorkspacesQueryKey() }),
        ])
      )
      .then(() => {
        if (server.enable_quick_access) {
          notifySuccess("Workspace successfully removed from quick access");
        } else {
          notifySuccess("Workspace successfully added to quick access");
        }
      })
      .catch(handleErrorWithNotify("Error occur while trying to bookmark server"))
      .finally(() => setCardProgress(""));
  };

  useEffect(() => {
    if (!workspaceTab) {
      setWorkspaceTab(
        canListWorkspaces ? WorkspaceTab.WORKSPACES_SEQ : WorkspaceTab.WORKSPACES_CURSOR
      );
    }
  }, [workspaceTab, canListWorkspaces]);

  return (
    <PageContainer progress={progress}>
      <TopBar />
      {!isNavDrawerAllowedToDisplay && (
        <div
          style={{
            marginTop: 128,
            width: "100vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <NoPermissionsToAccessWorkspacesEmptyState />
        </div>
      )}
      {isNavDrawerAllowedToDisplay && (
        <NavAndContentContainer nav={TenantNavDrawer}>
          <>
            {(() => {
              switch (path) {
                case "/workspace-directory":
                  return (
                    <>
                      <PageHeader<WorkspaceTab>
                        title="Workspace Management"
                        onChangeTab={setWorkspaceTab}
                        currentTab={workspaceTab}
                        tabs={[
                          <Tab
                            label="Workspaces"
                            value={
                              canListWorkspaces
                                ? WorkspaceTab.WORKSPACES_SEQ
                                : WorkspaceTab.WORKSPACES_CURSOR
                            }
                            key="workspaces"
                            id="workspaces-tab"
                          />,
                          ...(canListWorkspaces
                            ? [
                                <Tab
                                  label="Developer portals"
                                  value={WorkspaceTab.DEVELOPER_PORTALS_SEQ}
                                  key="developer-portals"
                                  id="developer-portals-tab"
                                />,
                              ]
                            : []),
                          ...(canListWorkspaces &&
                          (adminWorkspaceAccessEnabled || systemWorkspaceAccessEnabled)
                            ? [
                                <Tab
                                  label="Administrative"
                                  value={WorkspaceTab.ADMINISTRATIVE_SEQ}
                                  key="administrative"
                                  id="administrative-tab"
                                />,
                              ]
                            : []),
                        ]}
                      />

                      {workspaceTab === WorkspaceTab.WORKSPACES_SEQ && (
                        <WorkspacesControllerWithSeq
                          tab={workspaceTab}
                          setTab={setWorkspaceTab}
                          serversBindings={serversBindings}
                          cardProgress={cardProgress}
                          connectProgress={connectProgress}
                          deleteProgress={deleteProgress}
                          onDelete={onDelete}
                          onSelectServer={onSelectServer}
                          onConnectToExisting={onConnectToExisting}
                          onCreateDeveloperPortal={onCreateDeveloperPortal}
                          onUnbind={onUnbind}
                          onBindToTheme={onBindToTheme}
                          onUnbindTheme={onUnbindTheme}
                          handleBookmarkServer={handleBookmarkServer}
                          onWorkspaceCreate={onCreateClick}
                          workspaceType="regular"
                        />
                      )}

                      {workspaceTab === WorkspaceTab.WORKSPACES_CURSOR && (
                        <WorkspacesControllerWithCursor
                          tab={workspaceTab}
                          setTab={setWorkspaceTab}
                          serversBindings={serversBindings}
                          cardProgress={cardProgress}
                          connectProgress={connectProgress}
                          deleteProgress={deleteProgress}
                          onDelete={onDelete}
                          onSelectServer={onSelectServer}
                          onConnectToExisting={onConnectToExisting}
                          onCreateDeveloperPortal={onCreateDeveloperPortal}
                          onUnbind={onUnbind}
                          onBindToTheme={onBindToTheme}
                          onUnbindTheme={onUnbindTheme}
                          handleBookmarkServer={handleBookmarkServer}
                          onWorkspaceCreate={onCreateClick}
                        />
                      )}

                      {workspaceTab === WorkspaceTab.DEVELOPER_PORTALS_SEQ && (
                        <DeveloperPortalsController
                          serversBindings={serversBindings}
                          progress={cardProgress}
                          deleteProgress={deleteProgress}
                          onDelete={onDelete}
                          onSelectServer={onSelectServer}
                          onBindTheme={onBindToTheme}
                          onUnbindTheme={onUnbindTheme}
                          handleBookmarkServer={handleBookmarkServer}
                          onWorkspaceCreate={onCreateDeveloperPortal}
                        />
                      )}

                      {workspaceTab === WorkspaceTab.ADMINISTRATIVE_SEQ && (
                        <WorkspacesControllerWithSeq
                          tab={workspaceTab}
                          setTab={setWorkspaceTab}
                          serversBindings={serversBindings}
                          cardProgress={cardProgress}
                          connectProgress={connectProgress}
                          deleteProgress={deleteProgress}
                          onDelete={onDelete}
                          onSelectServer={onSelectServer}
                          onConnectToExisting={onConnectToExisting}
                          onCreateDeveloperPortal={onCreateDeveloperPortal}
                          onUnbind={onUnbind}
                          onBindToTheme={onBindToTheme}
                          onUnbindTheme={onUnbindTheme}
                          handleBookmarkServer={handleBookmarkServer}
                          workspaceType="admin,system"
                        />
                      )}
                    </>
                  );
                case "/organization-directory/:tab":
                  return (
                    <CheckTenantPermissions hasPermission="list_organizations">
                      <TenantOrganizations
                        cardProgress={cardProgress}
                        deleteProgress={deleteProgress}
                        onSelectServer={onSelectServer}
                      />
                    </CheckTenantPermissions>
                  );
                case "/mfa-settings":
                  return (
                    <CheckTenantPermissions hasPermission="read_mfa_methods">
                      <MfaSettings />
                    </CheckTenantPermissions>
                  );
                case "/message-providers/:tab":
                  return (
                    <CheckTenantPermissions hasPermission="read_mfa_methods">
                      <MessageProviders />
                    </CheckTenantPermissions>
                  );

                case "/brute-force-protection/:type":
                  return (
                    <CheckTenantPermissions hasPermission="manage_brute_force_protection">
                      <BruteForceProtectionByType />
                    </CheckTenantPermissions>
                  );
                case "/brute-force-protection":
                  return (
                    <CheckTenantPermissions hasPermission="read_brute_force_protection">
                      <BruteForceProtection />
                    </CheckTenantPermissions>
                  );
                case "/appearance/themes/editor/:id":
                  return (
                    <CheckTenantPermissions hasPermission="read_themes">
                      <ThemeDetails />
                    </CheckTenantPermissions>
                  );
                case "/appearance/themes":
                  return <Appearance />;
                case "/appearance/branding":
                  return (
                    <CheckTenantPermissions hasPermission="get_tenant">
                      <TenantBranding />
                    </CheckTenantPermissions>
                  );
                case "/appearance/translations/:locale":
                  return (
                    <CheckTenantPermissions hasPermission="get_tenant">
                      <TranslationController />
                    </CheckTenantPermissions>
                  );
                case "/appearance/translations":
                  return (
                    <CheckTenantPermissions hasPermission="get_tenant">
                      <TranslationsController />
                    </CheckTenantPermissions>
                  );
                case "/identity-pools/pools/:id/users/:userID":
                  return <IdentityPoolUser />;
                case "/identity-pools/pools/:id/:tab":
                  return (
                    <CheckTenantPermissions hasPermission="list_identity_pools">
                      <IdentityPoolForView />
                    </CheckTenantPermissions>
                  );
                case "/identity-pools/schemas/:id/:tab":
                  return (
                    <CheckTenantPermissions hasPermission="list_identity_pools">
                      <IdentitySchema />
                    </CheckTenantPermissions>
                  );
                case "/identity-pools/:tab":
                  return (
                    <CheckTenantPermissions hasPermission="list_identity_pools">
                      <IdentityPools />
                    </CheckTenantPermissions>
                  );
                case "/permissions":
                  return (
                    <CheckTenantPermissions hasPermission="read_permission_systems">
                      <PermissionsController />
                    </CheckTenantPermissions>
                  );
                case "/permissions/:id/:tab":
                  return (
                    <CheckTenantPermissions hasPermission="read_permission_systems">
                      <PermissionController />
                    </CheckTenantPermissions>
                  );
                case "/administrators/users":
                  return <AdministratorManagement />;
                case "/administrators/sign-in":
                  return <AdministratorSettingsV2 />;
                case "/administrators/early-access-features":
                  return (
                    <CheckTenantPermissions hasPermission="get_tenant">
                      <BetaFeatures />
                    </CheckTenantPermissions>
                  );
                default:
                  return null;
              }
            })()}
          </>
        </NavAndContentContainer>
      )}
    </PageContainer>
  );
}

const NoPermissionsToAccessWorkspacesEmptyState = () => (
  <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
    <img src={emptyState} alt="no workspaces found" style={{ width: 36, height: 36 }} />

    <Typography style={{ color: "#969799", fontSize: 14, fontWeight: 400, marginTop: 16 }}>
      No resources found
    </Typography>
    <Typography
      style={{ color: "#A4A6A7", fontSize: 10, fontWeight: 400, marginTop: 6 }}
      id="empty-state-description"
    >
      You are not entitled to access any workspaces or identity pools. Please contact administrator
    </Typography>
  </div>
);
