import { useMutation } from "@tanstack/react-query";

import { TranslationsApiUpsertTranslationRequest } from "@cloudentity/acp-admin";

import { getTenantId } from "../../common/api/paths";
import adminTranslationsApi from "./adminTranslationsApi";
import { getTranslationQueryKey, listTranslationsQueryKey } from "./adminTranslationsQuery";
import { queryClient } from "./queryClient";

export const useTranslationMutation = ({ locale }: { locale: string }) => {
  return useMutation({
    mutationFn: (req: TranslationsApiUpsertTranslationRequest) => {
      return adminTranslationsApi.upsertTranslation(req);
    },
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({ queryKey: getTranslationQueryKey(getTenantId(), locale) }),
        queryClient.invalidateQueries({ queryKey: listTranslationsQueryKey(getTenantId()) }),
      ]),
  });
};
