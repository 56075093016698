import React, { lazy } from "react";

import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

import { TenantMetadataRegistration } from "../../../admin/components/getStartedV2/utils";
import { useGetTenant } from "../../../admin/services/adminTenantsQuery";
import { useGetUserInfo } from "../../../admin/services/oauth2Query";
import logo from "../../../assets/images/logos/sa/sa_rgb_wht.svg";
import { getTenantId } from "../../api/paths";
import Button from "../Button";
import ConfirmationDialog from "../ConfirmationDialog";

const Confetti = lazy(() => import("../Confetti"));

const useStyles = makeStyles()(() => ({
  imgContainer: {
    height: 250,
    background: "linear-gradient(180deg, #BDCC91 0%, #10B0E9 100%)",
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    "& > img": {
      width: 250,
    },
  },
  content: {
    marginTop: 24,
    "& > p": {
      marginTop: 16,
    },
  },
}));

interface Props {
  onClose: () => void;
  title?: string;
}

export default function UpgradeModal({ onClose, title }: Props) {
  const { classes } = useStyles();
  const tenantQuery = useGetTenant(getTenantId());
  const userinfoQuery = useGetUserInfo();

  const firstName =
    (tenantQuery.data?.metadata?.registration as TenantMetadataRegistration)?.first_name ||
    userinfoQuery.data?.given_name ||
    "";

  return (
    <ConfirmationDialog
      id="upgrade-modal"
      onlyExtraActions
      onConfirm={() => {}}
      onCancel={() => {}}
      extraActions={
        <Button variant="text" color="secondary" onClick={onClose}>
          Close
        </Button>
      }
      mode="info"
      buttonsStyle={{ marginTop: 16, marginBottom: -8 }}
      content={
        <>
          <div className={classes.imgContainer}>
            <img src={logo} alt="logo" />
            <Confetti />
          </div>
          <div className={classes.content}>
            <Typography variant="h3">{title || "Upgrade Request Received!"}</Typography>
            <Typography>
              Thank you <b>{firstName}</b> for taking the next step with us! 🎉
            </Typography>
            <Typography>
              We've received your request, and our accounts team will be in touch shortly to guide
              you through the next steps.
            </Typography>
            <Typography>
              In the meantime, if you have any questions, feel free to reach out at{" "}
              <Link href="mailto:ciamtrial@secureauth.com">ciamtrial@secureauth.com</Link>. We're
              here to help!
            </Typography>
          </div>
        </>
      }
    />
  );
}
