import React, { useState } from "react";

import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useQueryClient } from "@tanstack/react-query";
import { ChevronDown, ChevronUp } from "react-feather";
import { makeStyles } from "tss-react/mui";

import { PoolResponse, PoolResponseAuthenticationMechanismsEnum } from "@cloudentity/acp-identity";
import { IDP } from "@cloudentity/acp-root";

import Button from "../../../../common/components/Button";
import UpgradeModal from "../../../../common/components/upgrade/UpgradeModal";
import { BRAND_NAME } from "../../../../common/theme/theme";
import { useGetLicense } from "../../../services/adminLicenseQuery";
import { useWorkspace } from "../../common/useWorkspace";
import { providers } from "../../identities/identities.utils";
import CreateIdp from "../../identities/identities_view_tabbed/identitiesListSimple/CreateIdp";
import {
  handleExistingIdpCheckboxChange,
  handlePoolMechanismChange,
} from "../../identities/identities_view_tabbed/identitiesListSimple/IdentitiesListSimple";
import IdentitiesListSimplePreview from "../../identities/identities_view_tabbed/identitiesListSimple/IdentitiesListSimplePreview";
import { poolAuthMechanismMapper } from "../../workspaceDirectory/identityPools/identityPoolCreate/IdentityPoolsAuthnSelect";
import { SmallInfoText, Steps, useCommonStyles } from "../utils";
import ProgressIndicator from "./ProgressIndicator";
import SignInStepSwitchCard from "./SignInStepSwitchCard";
import SignInStepUnlockDialog from "./SignInStepUnlockDialog";

const useStyles = makeStyles()(theme => ({
  container: {
    padding: 32,
  },
  right: {
    flex: 1,
    overflow: "hidden",
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    background: "none",
    border: "solid 1px black",
    color: theme.palette.secondary.light,
    width: 30,
    height: 30,
    marginRight: 8,
  },
}));
interface Props {
  poolIdps: IDP[];
  externalIdps: IDP[];
  pool: PoolResponse | undefined;
  selectedAuthenticationMethods: (PoolResponseAuthenticationMechanismsEnum | string)[];
  setSelectedAuthenticationMethods: React.Dispatch<
    React.SetStateAction<(PoolResponseAuthenticationMechanismsEnum | string)[]>
  >;
  onChangeStep: (step: Steps) => void;
  idpsLoading: boolean;
}

export default function SignInStep({
  poolIdps,
  externalIdps,
  pool,
  onChangeStep,
  idpsLoading,
}: Props) {
  const { cx, classes: commonClasses } = useCommonStyles();
  const { classes } = useStyles();
  const queryClient = useQueryClient();
  const [workspace] = useWorkspace();
  const licenseQuery = useGetLicense();
  const hasEnterpriseIdpsCapability =
    !licenseQuery.data?.enforcement_enabled || licenseQuery.data?.has_enterprise_idps_capability;

  const [openAdditional, setOpenAdditional] = useState(false);
  const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
  const [createIdp, setCreateIdp] = useState("");
  const [focusedCardIndex, setFocusedCardIndex] = useState(0);
  const [cardProgress, setCardProgress] = useState("");
  const [sentUpgradeDialog, setSentUpgradeDialog] = useState(false);

  const poolMethods = [
    PoolResponseAuthenticationMechanismsEnum.Webauthn,
    PoolResponseAuthenticationMechanismsEnum.Otp,
    PoolResponseAuthenticationMechanismsEnum.Password,
  ];

  const idps = ["google", "github", "azure"];

  const poolProvider = providers.find(provider => provider.method === "identity_pool");

  return (
    <>
      <div className={commonClasses.leftWithPreview}>
        <div className={classes.container}>
          <div className={commonClasses.intro}>
            <Typography
              variant="h2"
              className={commonClasses.header}
              id="onboarding-select-sign-in-header"
            >
              Select your customer sign-in options
            </Typography>
            <SmallInfoText fontSize={14}>
              You have the flexibility to customize it later, so there's no need to worry
            </SmallInfoText>
            <ProgressIndicator value={33} text="1" caption="Out of 3" />
            <Divider className={commonClasses.divider} />
          </div>

          {poolIdps.length === 0 && (
            <div>
              <Typography variant="h5">Authentication methods</Typography>
              <div style={{ margin: "16px 0" }}>
                <SmallInfoText>No {BRAND_NAME} provider</SmallInfoText>
              </div>
            </div>
          )}

          {poolIdps.length === 1 && (
            <div>
              <Typography variant="h5">Authentication methods</Typography>
              <div style={{ margin: "16px 0" }}>
                {poolMethods.map(method => {
                  const { id, label, icon } = poolAuthMechanismMapper[method];
                  const isChecked = !!pool?.authentication_mechanisms?.includes(method);
                  return (
                    <SignInStepSwitchCard
                      key={id}
                      id={id}
                      name={label}
                      icon={icon}
                      noSvgStyle={method === PoolResponseAuthenticationMechanismsEnum.Webauthn}
                      checked={isChecked}
                      onChange={checked => {
                        setFocusedCardIndex(1);
                        handlePoolMechanismChange(
                          queryClient,
                          pool,
                          id,
                          method,
                          checked,
                          setCardProgress
                        );
                      }}
                      disabled={
                        !!cardProgress ||
                        (isChecked && method === PoolResponseAuthenticationMechanismsEnum.Webauthn)
                      }
                    />
                  );
                })}
              </div>
            </div>
          )}

          {poolIdps.length > 1 && (
            <>
              <Typography variant="h5">{BRAND_NAME} providers</Typography>
              <div style={{ margin: "16px 0" }}>
                {poolIdps.map(idp => (
                  <SignInStepSwitchCard
                    key={idp.id ?? ""}
                    id={idp.id ?? ""}
                    name={idp.name ?? ""}
                    icon={<img src={poolProvider?.icon} alt={idp.id} />}
                    checked={!idp.disabled}
                    disabled={!!cardProgress}
                    onChange={checked => {
                      setFocusedCardIndex(0);
                      handleExistingIdpCheckboxChange(
                        queryClient,
                        workspace,
                        idp.id ?? "",
                        idp,
                        checked,
                        setCardProgress
                      );
                    }}
                  />
                ))}
              </div>
            </>
          )}

          <div className={commonClasses.additionalSection}>
            <div className={commonClasses.flexContainer}>
              <Typography variant="h5">All Connections</Typography>
              <Button
                variant="text"
                className={commonClasses.buttonExplore}
                onClick={() => setOpenAdditional(isOpen => !isOpen)}
              >
                <Typography variant="textSM">Explore connections</Typography>
                {openAdditional ? (
                  <ChevronUp size={16} style={{ marginLeft: 4 }} />
                ) : (
                  <ChevronDown size={16} style={{ marginLeft: 4 }} />
                )}
              </Button>
            </div>
            <SmallInfoText>
              Configure social connections like Google or Upgrade to enterprise connections so that
              you can let your users sign in with them.
            </SmallInfoText>
            {openAdditional && (
              <div>
                <Typography variant="h6" style={{ margin: "16px 0" }}>
                  Social Connections
                </Typography>

                {idps.map((idp, i) => {
                  const provider = providers.find(provider => provider.method === idp);
                  const found = externalIdps.find(v => v.method === idp);
                  return provider ? (
                    <SignInStepSwitchCard
                      key={idp}
                      id={idp}
                      name={provider.name}
                      icon={<img src={provider.icon} alt={idp} />}
                      idpAvatar
                      checked={!!found && !found.disabled}
                      disabled={!!cardProgress}
                      onChange={checked => {
                        if (checked && !found) {
                          setCreateIdp(idp);
                        }

                        setFocusedCardIndex(0);
                        handleExistingIdpCheckboxChange(
                          queryClient,
                          workspace,
                          found?.id ?? "",
                          found,
                          checked,
                          setCardProgress
                        );
                      }}
                      noMargin
                      hasPrev={i > 0}
                      hasNext={i < poolMethods.length - 1}
                    />
                  ) : null;
                })}

                <Typography
                  variant="h6"
                  className={commonClasses.flexContainer}
                  style={{ margin: "16px 0" }}
                >
                  Enterprise Connections
                  <Button
                    variant="outlined"
                    size="small"
                    style={{ fontSize: 12 }}
                    onClick={() => setOpenUpgradeDialog(true)}
                  >
                    Learn more
                  </Button>
                </Typography>
                {!hasEnterpriseIdpsCapability && (
                  <SmallInfoText>
                    Upgrade to the Enterprise plan to add more connections and unlock an entirely
                    new level of features.
                  </SmallInfoText>
                )}
              </div>
            )}
          </div>

          <div className={commonClasses.flexContainer}>
            <Button
              variant="text"
              onClick={() => onChangeStep(Steps.WELCOME)}
              color="inherit"
              className={commonClasses.backButton}
              id="onboarding-select-sign-in-back-button"
            >
              Back
            </Button>
            <Button
              variant="contained"
              onClick={() => onChangeStep(Steps.MAIN_USE_CASE_REGISTER_USER)}
              id="onboarding-select-sign-in-continue-button"
            >
              Continue
            </Button>
          </div>

          {openUpgradeDialog && (
            <SignInStepUnlockDialog
              externalIdps={externalIdps}
              setOpenUpgradeDialog={setOpenUpgradeDialog}
              setFocusedCardIndex={setFocusedCardIndex}
              setSentUpgradeDialog={setSentUpgradeDialog}
            />
          )}

          {createIdp && (
            <CreateIdp
              method={createIdp}
              workspace={workspace}
              handleClose={() => setCreateIdp("")}
              onCreated={() => {}}
              isHidden={false}
            />
          )}

          {sentUpgradeDialog && (
            <UpgradeModal
              onClose={() => {
                setSentUpgradeDialog(false);
              }}
            />
          )}
        </div>
      </div>
      <div className={cx(commonClasses.right, classes.right)}>
        <IdentitiesListSimplePreview
          workspace={workspace}
          poolIdps={poolIdps}
          externalIdps={externalIdps}
          pool={pool}
          focusedCardIndex={focusedCardIndex}
          setFocusedCardIndex={setFocusedCardIndex}
          idpsLoading={idpsLoading}
        />
      </div>
    </>
  );
}
