import { Translation, Translations } from "@cloudentity/acp-admin";

import adminTranslationsApi from "./adminTranslationsApi";
import { useQueryWithTenantPermissionCheck, withQueryError } from "./queryUtils";

const LIST_TRANSLATIONS_QUERY = "LIST_TRANSLATIONS_QUERY";
const GET_TRANSLATION_QUERY = "GET_TRANSLATION_QUERY";
const GET_BUILTIN_TRANSLATION_QUERY = "GET_BUILTIN_TRANSLATION_QUERY";

export const listTranslationsQueryKey = (tid: string) => [LIST_TRANSLATIONS_QUERY, tid];
export const getTranslationQueryKey = (tid: string, language: string) => [
  GET_TRANSLATION_QUERY,
  tid,
  language,
];
export const getBuiltInTranslationQueryKey = (tid: string, language: string) => [
  GET_BUILTIN_TRANSLATION_QUERY,
  tid,
  language,
];

export const useListTranslations = (tid: string, options?: any) =>
  useQueryWithTenantPermissionCheck(
    "get_tenant", // FIXME
    listTranslationsQueryKey(tid),
    withQueryError<Translations>(async () => {
      const data = await adminTranslationsApi.listTranslations();
      return data.data;
    }, "Error occurred while trying to fetch translations"),
    options
  );

export const useGetBuiltInTranslation = (tid: string, locale: string, options?: any) =>
  useQueryWithTenantPermissionCheck(
    "get_tenant", // FIXME
    getBuiltInTranslationQueryKey(tid, locale),
    withQueryError<Translation>(async () => {
      const data = await adminTranslationsApi.getBuiltinTranslation({ locale });
      return data.data;
    }, "Error occurred while trying to fetch default translation"),
    options
  );

export const useGetTranslation = (tid: string, locale: string, options?: any) =>
  useQueryWithTenantPermissionCheck(
    "get_tenant", // FIXME
    getTranslationQueryKey(tid, locale),
    withQueryError<Translation>(async () => {
      const data = await adminTranslationsApi.getTranslation({ locale });
      return data.data;
    }, "Error occurred while trying to fetch translation"),
    options
  );
