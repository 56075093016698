import React, { useState } from "react";

import { makeStyles } from "tss-react/mui";

import { Group, PoolResponse } from "@cloudentity/acp-identity";

import AdvancedTable from "../../../../../../common/components/table/AdvancedTable";
import { useCheckPoolPermissions } from "../../../../../services/adminIdentityPoolsQuery";
import PageContent from "../../../../common/PageContent";
import CreateGroupButton from "./CreateGroupButton";
import GroupsFilter from "./GroupsFilter";
import GroupsRowMenu from "./GroupsRowMenu";
import { useGroupsWithPagination } from "./useGroupsWithPagination";

interface Props {
  pool: PoolResponse;
}

export default function IdentityPoolGroups({ pool }: Props) {
  const { classes } = useStyles();

  const [moreMenu, setMoreMenu] = useState<{
    anchorEl: HTMLElement | undefined;
    group: Group | undefined;
  }>();

  const groupsWithPagination = useGroupsWithPagination(pool?.id || "");
  const checkPoolPermissions = useCheckPoolPermissions(pool?.id || "");

  return (
    <>
      <PageContent progress={false}>
        <div className={classes.toolBar}>
          <GroupsFilter onQuery={groupsWithPagination.onSetQuery} />
          <div style={{ marginLeft: 24, whiteSpace: "nowrap" }}>
            <CreateGroupButton identityPoolID={pool.id || ""} />
          </div>
        </div>
        <AdvancedTable<Group>
          tableId="identity-pool-groups"
          columns={[
            { id: "name", header: "Name", accessorFn: row => row.name },
            { id: "group_id", header: "Group ID", accessorFn: row => row.id },
            { id: "description", header: "Description", accessorFn: row => row.description },
          ]}
          activeColumns={["name", "group_id", "description"]}
          onRowClick={undefined}
          onMoreIconClick={
            checkPoolPermissions.data?.manage_identity_pool_groups
              ? (e, group) => {
                  setMoreMenu({ anchorEl: e.currentTarget, group });
                }
              : undefined
          }
          data={groupsWithPagination}
        />
      </PageContent>

      <GroupsRowMenu
        anchorEl={moreMenu?.anchorEl}
        onClose={() => setMoreMenu({ anchorEl: undefined, group: moreMenu?.group })}
        group={moreMenu?.group}
      />
    </>
  );
}

export const useStyles = makeStyles()(() => ({
  toolBar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 32,
  },
}));
