import { useCallback, useState } from "react";

import { Group } from "@cloudentity/acp-identity";

import { AdvancedTableData } from "../../../../../../common/components/table/types";
import { useListGroups } from "../../../../../services/adminGroupsQuery";

export const useGroupsWithPagination = (identityPoolID: string): AdvancedTableData<Group> => {
  const [afterGroupId, setAfterGroupId] = useState<string | undefined>();
  const [beforeGroupId, setBeforeGroupId] = useState<string | undefined>();
  const [limit, setLimit] = useState<number>(10);
  const [query, setQuery] = useState<string | undefined>();

  const requestParams = { limit, query };
  const listGroupsQuery = useListGroups({
    ipID: identityPoolID,
    ...requestParams,
    afterGroupId,
    beforeGroupId,
  });

  const nextGroupsQuery = useListGroups({
    ipID: identityPoolID,
    ...requestParams,
    beforeGroupId: undefined,
    afterGroupId: listGroupsQuery.isFetched ? listGroupsQuery.data?.at(-1)?.id : undefined,
  });

  const previousGroupsQuery = useListGroups({
    ipID: identityPoolID,
    ...requestParams,
    beforeGroupId: listGroupsQuery.isFetched ? listGroupsQuery.data?.at(0)?.id : undefined,
    afterGroupId: undefined,
  });

  const onReset = useCallback(() => {
    setBeforeGroupId(undefined);
    setAfterGroupId(undefined);
  }, []);

  const onSetLimit = useCallback(
    (v: number) => {
      if (v !== limit) {
        setLimit(v);
        onReset();
      }
    },
    [onReset, limit]
  );

  const onSetQuery = useCallback(
    (v: string | undefined) => {
      if (v !== query) {
        setQuery(v);
        onReset();
      }
    },
    [onReset, query]
  );

  const onNext = useCallback(() => {
    setBeforeGroupId(undefined);
    setAfterGroupId(listGroupsQuery.data?.at(-1)?.id);
  }, [listGroupsQuery.data]);

  const onBack = useCallback(() => {
    setBeforeGroupId(listGroupsQuery.data?.at(0)?.id);
    setAfterGroupId(undefined);
  }, [listGroupsQuery.data]);

  const hasNext = (nextGroupsQuery.data?.length ?? 0) > 0;
  const hasPrevious = (previousGroupsQuery.data?.length ?? 0) > 0;

  return {
    data: listGroupsQuery.data,
    isLoading: listGroupsQuery.isLoading,
    isSuccess: listGroupsQuery.isSuccess,
    params: requestParams,
    onReset,
    onSetLimit,
    onSetQuery,
    onNext,
    onBack,
    hasNext,
    hasPrevious,
  };
};
