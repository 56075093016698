import { Group, GroupsApiListGroupsRequest } from "@cloudentity/acp-identity";

import adminGroupsApi from "./adminGroupsApi";
import { useQueryWithPoolPermissionCheck, withQueryError } from "./queryUtils";

const LIST_GROUPS_KEY = "LIST_GROUPS_KEY";

export const getListGroupsKey = (poolId: string, req?: GroupsApiListGroupsRequest) =>
  req ? [LIST_GROUPS_KEY, poolId, req] : [LIST_GROUPS_KEY, poolId];

export const useListGroups = (req: GroupsApiListGroupsRequest, options?) =>
  useQueryWithPoolPermissionCheck<Group[]>(
    req.ipID,
    "read_identity_pool_groups",
    getListGroupsKey(req.ipID, req),
    withQueryError(async () => {
      const data = await adminGroupsApi.listGroups(req);
      return data.data;
    }, "Error occurred while trying to list identity pool groups"),
    options
  );
