import { useMutation } from "@tanstack/react-query";

import {
  GroupsApiCreateGroupRequest,
  GroupsApiDeleteGroupRequest,
  GroupsApiUpdateGroupRequest,
} from "@cloudentity/acp-identity";

import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../common/components/notifications/notificationService";
import adminGroupsApi from "./adminGroupsApi";
import { getListGroupsKey } from "./adminGroupsQuery";
import { queryClient } from "./queryClient";

export const useCreateGroupMutation = ({ poolId }: { poolId: string }) => {
  return useMutation({
    mutationFn: (req: GroupsApiCreateGroupRequest) => {
      return adminGroupsApi.createGroup(req);
    },
    onSuccess: () =>
      Promise.all([queryClient.invalidateQueries({ queryKey: getListGroupsKey(poolId) })]).then(
        () => notifySuccess("Group created successfully")
      ),
    onError: notifyErrorOrDefaultTo("Error occurred while trying to create group"),
  });
};

export const useUpdateGroupMutation = ({ poolId }: { poolId: string }) => {
  return useMutation({
    mutationFn: (req: GroupsApiUpdateGroupRequest) => {
      return adminGroupsApi.updateGroup(req);
    },
    onSuccess: () =>
      Promise.all([queryClient.invalidateQueries({ queryKey: getListGroupsKey(poolId) })]).then(
        () => notifySuccess("Group updated successfully")
      ),
    onError: notifyErrorOrDefaultTo("Error occurred while trying to update group"),
  });
};

export const useDeleteGroupMutation = ({ poolId }: { poolId: string }) => {
  return useMutation({
    mutationFn: (req: GroupsApiDeleteGroupRequest) => {
      return adminGroupsApi.deleteGroup(req);
    },
    onSuccess: () => {
      queryClient.removeQueries({ queryKey: getListGroupsKey(poolId) });
      notifySuccess("Group deleted successfully");
    },
    onError: notifyErrorOrDefaultTo("Error occurred while trying to delete group"),
  });
};
