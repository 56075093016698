import React, { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router";

import ChecklistRtl from "@mui/icons-material/ChecklistRtl";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { ChevronLeft, ChevronRight } from "react-feather";
import { makeStyles } from "tss-react/mui";

import bulbIcon from "../../../assets/images/icons/quickstart/lightbulb.svg";
import Button from "../../../common/components/Button";
import RouterLink from "../../../common/components/RouterLink";
import { TOP_BAR_HEIGHT } from "../../../common/components/nav/utils";
import PageContainer from "../common/PageContainer";
import PageContent from "../common/PageContent";
import { useWorkspace } from "../common/useWorkspace";
import { articles } from "./articles/articles";

const useStyles = makeStyles()(theme => ({
  paper: {
    padding: 32,
    "& p": {
      marginTop: 0,
      "&:last-of-type": {
        marginBottom: 0,
      },
    },
    "& ol": {
      marginBottom: 14,
    },
  },
  right: {
    alignSelf: "flex-start",
    position: "sticky",
    top: TOP_BAR_HEIGHT,
  },
  breadcrumbs: {
    marginBottom: 32,
    height: 16,
    color: "rgba(0, 0, 0, 0.38)",
  },
  title: {
    borderBottom: `solid 1px ${theme.custom.greys.border}`,
    width: "100%",
    display: "flex",
    alignItems: "center",
    paddingBottom: 16,
    marginBottom: 16,
  },
  titleIcon: {
    border: "solid 4px",
    borderColor: theme.custom.sa.brand.low,
    borderRadius: "100%",
    padding: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 8,
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 32,
  },
  button: {
    width: 200,
    display: "flex",
    alignItems: "center",
    padding: "12px 12px",
  },
  chevronContainer: {
    border: "solid 1px",
    borderColor: theme.palette.primary.main,
    borderRadius: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 2,
  },
  videoLabel: {
    background: theme.custom.sa.dark.darkGrey,
    borderRadius: "8px 32px 0 0",
    padding: "8px 20px 8px 8px",
    display: "inline-flex",
    alignItems: "center",
    color: "white",
    fontSize: 12,
    fontWeight: 600,
    "& > img": {
      height: 18,
      marginRight: 4,
    },
  },
  videoContainer: {
    background: theme.custom.sa.dark.darkGrey,
    padding: 8,
    display: "flex",
    borderRadius: "0 4px 4px 4px",
  },
}));

export default function QuickstartArticleController() {
  const { classes } = useStyles();
  const [workspace] = useWorkspace();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const videoRef = useRef<HTMLVideoElement>(null);

  const foundArticleIndex = articles.findIndex(article => article.id === id);
  const foundArticle = articles[foundArticleIndex];

  useEffect(() => {
    if (!foundArticle) {
      navigate(`/${workspace}/quickstart`);
    }
  }, [id, foundArticle, navigate, workspace]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
      videoRef.current?.load();
    });
  }, [id]);

  if (!foundArticle) {
    return null;
  }

  const prevArticle =
    articles[foundArticleIndex === 0 ? articles.length - 1 : foundArticleIndex - 1]?.id;
  const nextArticle =
    articles[foundArticleIndex === articles.length - 1 ? 0 : foundArticleIndex + 1]?.id;

  const Component = foundArticle.component;

  return (
    <PageContainer>
      <PageContent>
        <div className={classes.breadcrumbs}>
          <Typography variant="textMD">
            <RouterLink to={`/${workspace}/quickstart`}>Quickstart</RouterLink> &gt; What's next
          </Typography>
        </div>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={7}>
            <Paper className={classes.paper}>
              <Typography variant="textLG" className={classes.title} id="quickstart-article-title">
                <div className={classes.titleIcon}>
                  <ChecklistRtl />
                </div>
                {foundArticle.title}
              </Typography>
              <Component />
            </Paper>
            <div className={classes.buttons}>
              <RouterLink to={`/${workspace}/quickstart/${prevArticle}`}>
                <Button
                  variant="outlined"
                  className={classes.button}
                  style={{ justifyContent: "flex-start" }}
                  id="quickstart-article-prev-button"
                >
                  <div className={classes.chevronContainer} style={{ marginRight: 12 }}>
                    <ChevronLeft size={16} />
                  </div>
                  Previous
                </Button>
              </RouterLink>
              <RouterLink to={`/${workspace}/quickstart/${nextArticle}`}>
                <Button
                  variant="outlined"
                  className={classes.button}
                  style={{ justifyContent: "flex-end" }}
                  id="quickstart-article-next-button"
                >
                  Next
                  <div className={classes.chevronContainer} style={{ marginLeft: 12 }}>
                    <ChevronRight size={16} />
                  </div>
                </Button>
              </RouterLink>
            </div>
          </Grid>
          {foundArticle.videoUrl && (
            <Grid item xs={12} lg={5} className={classes.right}>
              <div className={classes.videoLabel}>
                <img src={bulbIcon} alt="icon" /> Learn how to configure it
              </div>
              <div className={classes.videoContainer}>
                <video
                  controls
                  autoPlay
                  controlsList="nodownload"
                  width="100%"
                  muted
                  ref={videoRef}
                >
                  <source src={foundArticle.videoUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </Grid>
          )}
        </Grid>
      </PageContent>
    </PageContainer>
  );
}
