import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router";

import CircularProgress from "@mui/material/CircularProgress";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useQueryClient } from "@tanstack/react-query";
import { makeStyles } from "tss-react/mui";

import { Translation } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../common/api/paths";
import ConfirmationDialog from "../../../common/components/ConfirmationDialog";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../common/components/notifications/notificationService";
import adminTranslationsApi from "../../services/adminTranslationsApi";
import { listTranslationsQueryKey } from "../../services/adminTranslationsQuery";

const useStyles = makeStyles()(() => ({
  progressContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    opacity: 0.8,
    zIndex: 1,
  },
}));

interface Props {
  rowMenu: {
    anchorEl: HTMLElement;
    row: Translation;
  };
  onClose: () => void;
  translations: Translation[];
}

export default function TranslationsListRowMenu({ rowMenu, onClose, translations }: Props) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { classes } = useStyles();

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [progress, setProgress] = useState(false);

  const locale = useMemo(() => rowMenu.row.locale, [rowMenu]);
  const isBuiltIn = useMemo(() => rowMenu.row.is_built_in, [rowMenu]);
  const hasOverwrite = useMemo(
    () => translations.some(t => t.locale === locale && t.built_in_exists && !t.is_built_in),
    [locale, translations]
  );

  function handleRemove() {
    if (rowMenu?.row) {
      setProgress(true);

      adminTranslationsApi
        .deleteTranslation({
          locale: rowMenu?.row.locale,
        })
        .then(() =>
          queryClient.invalidateQueries({ queryKey: listTranslationsQueryKey(getTenantId()) })
        )
        .then(() => notifySuccess("Translation removed"))
        .then(onClose)
        .catch(notifyErrorOrDefaultTo("Error occurred while trying to remove language"))
        .finally(() => setProgress(false));
    }
  }

  function handleOverwriteBuiltIn() {
    setProgress(true);
    adminTranslationsApi
      .upsertTranslation({ locale })
      .then(() =>
        queryClient.invalidateQueries({ queryKey: listTranslationsQueryKey(getTenantId()) })
      )
      .then(onClose)
      .then(() => navigate(`/appearance/translations/${locale}`))
      .catch(notifyErrorOrDefaultTo("Error occurred while trying to create language"))
      .finally(() => setProgress(false));
  }

  return (
    <>
      <Menu
        id="clients-table-more-menu"
        anchorEl={rowMenu.anchorEl}
        keepMounted
        open={Boolean(rowMenu)}
        onClose={onClose}
      >
        <div style={{ position: "relative" }}>
          {progress && (
            <div className={classes.progressContainer}>
              <CircularProgress size={32} />
            </div>
          )}

          {!isBuiltIn && [
            <MenuItem onClick={() => navigate(`/appearance/translations/${rowMenu.row.locale}`)}>
              Edit
            </MenuItem>,
            <MenuItem onClick={() => setConfirmDelete(true)}>Delete</MenuItem>,
          ]}
          {isBuiltIn && !hasOverwrite && (
            <MenuItem onClick={handleOverwriteBuiltIn}>Overwrite</MenuItem>
          )}
        </div>
      </Menu>

      {confirmDelete && (
        <ConfirmationDialog
          title="Delete language"
          content={
            <>
              You're about to delete the <b>{rowMenu.row.locale}</b> language. This cannot be
              undone. Delete anyway?
            </>
          }
          confirmText="Delete"
          progress={progress}
          onCancel={() => setConfirmDelete(false)}
          onConfirm={handleRemove}
        />
      )}
    </>
  );
}
