import React from "react";

import Dialog from "../../../../../../common/components/Dialog";
import Form, { useForm } from "../../../../../../common/utils/forms/Form";
import FormFooter from "../../../../../../common/utils/forms/FormFooter";
import TextField from "../../../../../../common/utils/forms/TextField";
import TextFieldRequired from "../../../../../../common/utils/forms/TextFieldRequired";
import { useCreateGroupMutation } from "../../../../../services/adminGroupsMutations";

export default function CreateGroupDialog({
  poolId,
  onClose,
}: {
  poolId: string;
  onClose: () => void;
}) {
  const createGroupMutation = useCreateGroupMutation({ poolId });

  const form = useForm({
    id: "create-group",
    progress: createGroupMutation.isPending,
    mode: "onTouched",
  });

  const handleCreate = data => {
    createGroupMutation
      .mutateAsync({
        ipID: poolId,
        newGroup: {
          id: data.name, // TODO
          name: data.name,
          description: data.description,
          user_pool_id: poolId,
        },
      })
      .then(() => onClose());
  };

  return (
    <Dialog
      open
      onClose={onClose}
      title="Create Group"
      id="create-identity-pool-group-dialog"
      fullWidth
      maxWidth="md"
    >
      <Form form={form} noFormTag>
        <TextFieldRequired name="name" label="Name" placeholder="eg. Technical Admins" />
        <TextField
          name="description"
          label="Description"
          placeholder="eg. All technical staff who supportive to manage product operations"
        />

        <FormFooter onCancel={onClose} onSubmit={handleCreate} submitText="Create" />
      </Form>
    </Dialog>
  );
}
