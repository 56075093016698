import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";

import { Editor, loader } from "@monaco-editor/react";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useQueryClient } from "@tanstack/react-query";
import { makeStyles } from "tss-react/mui";

import { getTenantId } from "../../../common/api/paths";
import Chip from "../../../common/components/Chip";
import FormSection from "../../../common/components/FormSection";
import LoadingButton from "../../../common/components/LoadingButton";
import RouteLeavingGuardSimple from "../../../common/components/RouteLeavingGuardSimple";
import RouterLink from "../../../common/components/RouterLink";
import { TOP_BAR_HEIGHT } from "../../../common/components/nav/utils";
import {
  notifyError,
  notifySuccess,
} from "../../../common/components/notifications/notificationService";
import { useGetTenant } from "../../services/adminTenantsQuery";
import adminTranslationsApi from "../../services/adminTranslationsApi";
import {
  getTranslationQueryKey,
  listTranslationsQueryKey,
  useGetBuiltInTranslation,
  useGetTranslation,
  useListTranslations,
} from "../../services/adminTranslationsQuery";
import PageContent from "../common/PageContent";
import PageHeader, { PAGE_HEADER_HEIGHT } from "../common/PageHeader";
import TranslationSwitch from "./TranslationSwitch";
import { acceptedLanguageObjects, parseObjectToYaml, parseYamlToObject } from "./utils";

loader.config({
  paths: {
    vs: `${document.baseURI}assets/monaco/min/vs`,
  },
});

const useStyles = makeStyles()(theme => ({
  editor: {
    border: "solid 1px",
    borderColor: theme.custom.greys.border,
    borderRadius: 4,
    padding: 1,
    flex: 1,
  },
  paper: {
    padding: 32,
    height: `calc(100vh - ${TOP_BAR_HEIGHT}px - ${PAGE_HEADER_HEIGHT}px - 122px - 64px)`,
    display: "flex",
    flexDirection: "column",
  },
  options: {
    display: "flex",
    alignItems: "center",
    gap: 16,
  },
}));

export default function TranslationController() {
  const { locale = "" } = useParams<{ locale: string }>();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { classes } = useStyles();

  const [error, setError] = useState("");
  const [value, setValue] = useState("");
  const [progress, setProgress] = useState(false);

  const getTenantQuery = useGetTenant(getTenantId());
  const translationSettings = getTenantQuery.data?.settings?.translations ?? {};

  const translationsListQuery = useListTranslations(getTenantId());
  const translations = useMemo(
    () => translationsListQuery.data?.translations ?? [],
    [translationsListQuery.data]
  );

  const isOverwrite = useMemo(
    () => translations.some(t => t.locale === locale && t.built_in_exists),
    [locale, translations]
  );

  const getBuiltInEnglishTranslationQuery = useGetBuiltInTranslation(getTenantId(), "en-US", {
    enabled: !isOverwrite,
  });
  const builtInEnglishTranslation = getBuiltInEnglishTranslationQuery.data;

  const getBuiltInTranslationQuery = useGetBuiltInTranslation(getTenantId(), locale, {
    enabled: isOverwrite,
  });
  const builtInTranslation = getBuiltInTranslationQuery.data;

  const getTranslationQuery = useGetTranslation(getTenantId(), locale);
  const translation = getTranslationQuery.data;

  const translationContent = parseObjectToYaml(getTranslationQuery.data?.content);
  const builtInTranslationContent = parseObjectToYaml(
    builtInTranslation?.content ?? builtInEnglishTranslation?.content
  );

  const isEnabled = !!getTranslationQuery.data?.enabled;

  const hasChanges = value && value !== translationContent;

  const name = acceptedLanguageObjects.find(t => t.code === locale)?.name;

  function handleSave(params: { content: string }) {
    setProgress(true);
    const locale = getTranslationQuery.data?.locale;

    adminTranslationsApi
      .upsertTranslation({
        locale,
        translation: {
          ...translation,
          content: parseYamlToObject(params.content),
        },
      })
      .then(() =>
        queryClient.invalidateQueries({ queryKey: getTranslationQueryKey(getTenantId(), locale) })
      )
      .then(() =>
        queryClient.invalidateQueries({ queryKey: listTranslationsQueryKey(getTenantId()) })
      )
      .then(() => {
        notifySuccess("Translation saved");
      })
      .catch(err => {
        notifyError("Error occurred while trying to save translation");
        setError(err.response?.data?.error);
      })
      .finally(() => setProgress(false));
  }

  useEffect(() => {
    setValue(parseObjectToYaml(translation?.content) ?? "");
  }, [translation]);

  useEffect(() => {
    if (
      getBuiltInTranslationQuery.isError ||
      getBuiltInEnglishTranslationQuery.isError ||
      getTranslationQuery.isError
    ) {
      navigate("/appearance/translations");
    }
  }, [
    getBuiltInTranslationQuery.isError,
    getBuiltInEnglishTranslationQuery.isError,
    getTranslationQuery.isError,
    navigate,
  ]);

  const loading =
    getTenantQuery.isLoading ||
    translationsListQuery.isLoading ||
    getTranslationQuery.isLoading ||
    getBuiltInTranslationQuery.isLoading;

  return (
    <>
      {loading ? (
        <LinearProgress
          style={{ position: "absolute", top: 0, left: 0, width: "100%", zIndex: 1000 }}
        />
      ) : (
        <>
          <PageHeader
            title={name || locale || "Translation"}
            breadcrumb={
              <>
                <RouterLink to="/appearance/translations">Translations</RouterLink> &gt; {locale}
              </>
            }
          />
          <PageContent>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Paper style={{ padding: 32 }}>
                  <div className={classes.options}>
                    <TranslationSwitch
                      checked={isEnabled}
                      isBuiltIn={false}
                      isOverwritten={false}
                      locale={locale}
                      translationSettings={translationSettings}
                    />
                    <Typography variant="body2">Enable translation</Typography>
                  </div>
                </Paper>
              </Grid>

              <Grid item xs={12} lg={6}>
                <Paper className={classes.paper}>
                  <FormSection title="Custom" />

                  <Editor
                    defaultLanguage="yaml"
                    options={{
                      minimap: {
                        enabled: false,
                      },
                      scrollBeyondLastLine: false,
                      automaticLayout: true,
                    }}
                    height="calc(100% - 152px)"
                    value={value}
                    onChange={v => {
                      if (parseYamlToObject(v ?? "") === undefined) {
                        setError("Syntax error");
                        return;
                      }
                      setValue(v ?? "");
                      setError("");
                    }}
                    className={classes.editor}
                  />
                  {error && (
                    <Typography variant="textSM" color="error" style={{ marginTop: 4 }}>
                      {error}
                    </Typography>
                  )}
                  <div style={{ textAlign: "right", width: "100%", marginTop: 32 }}>
                    <LoadingButton
                      id="save-button"
                      variant="contained"
                      onClick={() => handleSave({ content: value })}
                      disabled={!hasChanges || !!error}
                      loading={progress}
                    >
                      Save
                    </LoadingButton>
                  </div>
                </Paper>
              </Grid>

              <Grid item xs={12} lg={6}>
                <Paper className={classes.paper} style={{ background: "#F7FAFF" }}>
                  <FormSection title="Default" />

                  <Editor
                    defaultLanguage="yaml"
                    options={{
                      minimap: {
                        enabled: false,
                      },
                      scrollBeyondLastLine: false,
                      readOnly: true,
                      automaticLayout: true,
                    }}
                    height="calc(100% - 156px)"
                    defaultValue={builtInTranslationContent}
                    className={classes.editor}
                  />

                  <div style={{ textAlign: "right", width: "100%", marginTop: 32 }}>
                    <Chip label="Read only" />
                    <Chip label="YAML" style={{ marginRight: 0 }} />
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </PageContent>
          <RouteLeavingGuardSimple when={!!hasChanges} />
        </>
      )}
    </>
  );
}
