"use client";
import { createMakeStyles, TssCacheProvider } from "./makeStyles";
export { createMakeStyles, TssCacheProvider };
import { createWithStyles } from "./withStyles";
export { createWithStyles };
import { createTss } from "./tss";
export { createTss };
/** @see <https://docs.tss-react.dev/api/keyframes> */
export { keyframes } from "@emotion/react";
/** @see <https://docs.tss-react.dev/api/globalstyles> */
export { GlobalStyles } from "./GlobalStyles";
/** @see <https://docs.tss-react.dev/api/makestyles> */
export function createMakeAndWithStyles(params) {
    return {
        ...createMakeStyles(params),
        ...createWithStyles(params)
    };
}
export const { tss } = createTss({
    "useContext": () => ({})
});
export const useStyles = tss.create({});
