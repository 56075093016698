import React, { useState } from "react";

import LinearProgress from "@mui/material/LinearProgress";
import Tab from "@mui/material/Tab";
import { useQueryClient } from "@tanstack/react-query";

import { TenantTranslationsSettings } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../common/api/paths";
import { handleErrorWithNotify } from "../../../common/components/notifications/notificationService";
import adminTenantsApi from "../../services/adminTenantsApi";
import { getTenantQueryKey, useGetTenant } from "../../services/adminTenantsQuery";
import { useListTranslations } from "../../services/adminTranslationsQuery";
import PageContent from "../common/PageContent";
import PageHeader from "../common/PageHeader";
import TranslationsList from "./TranslationsList";
import TranslationsSettings from "./TranslationsSettings";

export default function TranslationsController() {
  const queryClient = useQueryClient();
  const [tab, setTab] = useState<"languages" | "settings">("languages");

  const getTenantQuery = useGetTenant(getTenantId());
  const translationsListQuery = useListTranslations(getTenantId());
  const translations = translationsListQuery.data?.translations ?? [];

  function handleTranslationsSettingsUpdate(settings: TenantTranslationsSettings) {
    return adminTenantsApi
      .updateTenant({
        tenant: {
          ...getTenantQuery.data,
          settings: {
            ...getTenantQuery.data?.settings,
            translations: {
              ...getTenantQuery.data?.settings?.translations,
              ...settings,
            },
          },
        },
      })
      .then(res => {
        queryClient.setQueryData(getTenantQueryKey(getTenantQuery.data?.id), res.data);
        return res;
      })
      .catch(handleErrorWithNotify("Error occurred while trying to update settings"));
  }

  return (
    <>
      {translationsListQuery.isLoading || getTenantQuery.isLoading ? (
        <LinearProgress
          style={{ position: "absolute", top: 0, left: 0, width: "100%", zIndex: 1000 }}
        />
      ) : (
        <>
          <PageHeader
            title="Translations"
            tabs={[
              <Tab id="languages-tab" label="Languages" value="languages" key="languages" />,
              <Tab id="settings-tab" label="Settings" value="settings" key="settings" />,
            ]}
            currentTab={tab}
            onChangeTab={(t: "languages" | "settings") => setTab(t)}
          />
          <PageContent>
            {tab === "languages" && (
              <TranslationsList
                translations={translations}
                handleUpdateSettings={handleTranslationsSettingsUpdate}
              />
            )}
            {tab === "settings" && (
              <TranslationsSettings
                translations={translations}
                handleUpdate={handleTranslationsSettingsUpdate}
              />
            )}
          </PageContent>
        </>
      )}
    </>
  );
}
