import {
  TranslationsApi,
  TranslationsApiDeleteTranslationRequest,
  TranslationsApiGetBuiltinTranslationRequest,
  TranslationsApiGetTranslationRequest,
  TranslationsApiUpsertTranslationRequest,
} from "@cloudentity/acp-admin";

import { adminApiClazz } from "./admin-services.common";

const api: () => TranslationsApi = adminApiClazz(TranslationsApi);

const adminTranslationsApi = {
  listTranslations: () => api().listTranslations(),
  upsertTranslation: (req: TranslationsApiUpsertTranslationRequest) => api().upsertTranslation(req),
  getBuiltinTranslation: (req: TranslationsApiGetBuiltinTranslationRequest) =>
    api().getBuiltinTranslation(req),
  getTranslation: (req: TranslationsApiGetTranslationRequest) => api().getTranslation(req),
  deleteTranslation: (req: TranslationsApiDeleteTranslationRequest) => api().deleteTranslation(req),
};

export default adminTranslationsApi;
