"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Cloudentity API
 * Cloudentity Public API  The POST APIs support idempotency for safely retrying requests without accidentally performing the same operation twice.  To use idempotent requests:  1. Generate a unique idempotency key (32-255 characters long). 2. Include the key in the X-Idempotency-Key header of your POST request. 3. Retry failed requests with the same key within 1 minute.  Responses for replayed requests will include the X-Idempotent-Replayed: true header.
 *
 * The version of the OpenAPI document: latest
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransientOtpApi = exports.TransientOtpApiFactory = exports.TransientOtpApiFp = exports.TransientOtpApiAxiosParamCreator = exports.TokensApi = exports.TokensApiFactory = exports.TokensApiFp = exports.TokensApiAxiosParamCreator = exports.SessionsApi = exports.SessionsApiFactory = exports.SessionsApiFp = exports.SessionsApiAxiosParamCreator = exports.EnvironmentApi = exports.EnvironmentApiFactory = exports.EnvironmentApiFp = exports.EnvironmentApiAxiosParamCreator = exports.ConsentsApi = exports.ConsentsApiFactory = exports.ConsentsApiFp = exports.ConsentsApiAxiosParamCreator = exports.ClientsApi = exports.ClientsApiFactory = exports.ClientsApiFp = exports.ClientsApiAxiosParamCreator = exports.ApplicationsApi = exports.ApplicationsApiFactory = exports.ApplicationsApiFp = exports.ApplicationsApiAxiosParamCreator = exports.TransientOTPResponseMechanismEnum = exports.TransientOTPRequestMechanismEnum = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("./common");
// @ts-ignore
var base_1 = require("./base");
/**
    * @export
    * @enum {string}
    */
var TransientOTPRequestMechanismEnum;
(function (TransientOTPRequestMechanismEnum) {
    TransientOTPRequestMechanismEnum["Sms"] = "sms";
    TransientOTPRequestMechanismEnum["Email"] = "email";
})(TransientOTPRequestMechanismEnum || (exports.TransientOTPRequestMechanismEnum = TransientOTPRequestMechanismEnum = {}));
/**
    * @export
    * @enum {string}
    */
var TransientOTPResponseMechanismEnum;
(function (TransientOTPResponseMechanismEnum) {
    TransientOTPResponseMechanismEnum["Sms"] = "sms";
    TransientOTPResponseMechanismEnum["Email"] = "email";
})(TransientOTPResponseMechanismEnum || (exports.TransientOTPResponseMechanismEnum = TransientOTPResponseMechanismEnum = {}));
/**
 * ApplicationsApi - axios parameter creator
 * @export
 */
var ApplicationsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * User applications.
         * @summary Lists user applications.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserApplications: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/applications";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication default required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "default", ["list_clients_with_access"], configuration)];
                        case 1:
                            // authentication default required
                            // oauth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.ApplicationsApiAxiosParamCreator = ApplicationsApiAxiosParamCreator;
/**
 * ApplicationsApi - functional programming interface
 * @export
 */
var ApplicationsApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.ApplicationsApiAxiosParamCreator)(configuration);
    return {
        /**
         * User applications.
         * @summary Lists user applications.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserApplications: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listUserApplications(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.ApplicationsApiFp = ApplicationsApiFp;
/**
 * ApplicationsApi - factory interface
 * @export
 */
var ApplicationsApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.ApplicationsApiFp)(configuration);
    return {
        /**
         * User applications.
         * @summary Lists user applications.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserApplications: function (options) {
            return localVarFp.listUserApplications(options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.ApplicationsApiFactory = ApplicationsApiFactory;
/**
 * ApplicationsApi - object-oriented interface
 * @export
 * @class ApplicationsApi
 * @extends {BaseAPI}
 */
var ApplicationsApi = /** @class */ (function (_super) {
    __extends(ApplicationsApi, _super);
    function ApplicationsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * User applications.
     * @summary Lists user applications.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApi
     */
    ApplicationsApi.prototype.listUserApplications = function (options) {
        var _this = this;
        return (0, exports.ApplicationsApiFp)(this.configuration).listUserApplications(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ApplicationsApi;
}(base_1.BaseAPI));
exports.ApplicationsApi = ApplicationsApi;
/**
 * ClientsApi - axios parameter creator
 * @export
 */
var ClientsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Each client contains list of scopes that user agreed to.
         * @summary List clients that user shared data with
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClientsWithAccess: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/clients";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication default required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "default", ["list_clients_with_access"], configuration)];
                        case 1:
                            // authentication default required
                            // oauth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Upon removal client won\'t be able to use user data anymore.
         * @summary Revoke client access
         * @param {string} cid Client id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeClientAccess: function (cid_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([cid_1], args_1, true), void 0, function (cid, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'cid' is not null or undefined
                            (0, common_1.assertParamExists)('revokeClientAccess', 'cid', cid);
                            localVarPath = "/clients/{cid}"
                                .replace("{".concat("cid", "}"), encodeURIComponent(String(cid)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication default required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "default", ["revoke_client_access"], configuration)];
                        case 1:
                            // authentication default required
                            // oauth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.ClientsApiAxiosParamCreator = ClientsApiAxiosParamCreator;
/**
 * ClientsApi - functional programming interface
 * @export
 */
var ClientsApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.ClientsApiAxiosParamCreator)(configuration);
    return {
        /**
         * Each client contains list of scopes that user agreed to.
         * @summary List clients that user shared data with
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClientsWithAccess: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listClientsWithAccess(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Upon removal client won\'t be able to use user data anymore.
         * @summary Revoke client access
         * @param {string} cid Client id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeClientAccess: function (cid, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.revokeClientAccess(cid, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.ClientsApiFp = ClientsApiFp;
/**
 * ClientsApi - factory interface
 * @export
 */
var ClientsApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.ClientsApiFp)(configuration);
    return {
        /**
         * Each client contains list of scopes that user agreed to.
         * @summary List clients that user shared data with
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClientsWithAccess: function (options) {
            return localVarFp.listClientsWithAccess(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Upon removal client won\'t be able to use user data anymore.
         * @summary Revoke client access
         * @param {string} cid Client id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeClientAccess: function (cid, options) {
            return localVarFp.revokeClientAccess(cid, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.ClientsApiFactory = ClientsApiFactory;
/**
 * ClientsApi - object-oriented interface
 * @export
 * @class ClientsApi
 * @extends {BaseAPI}
 */
var ClientsApi = /** @class */ (function (_super) {
    __extends(ClientsApi, _super);
    function ClientsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Each client contains list of scopes that user agreed to.
     * @summary List clients that user shared data with
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    ClientsApi.prototype.listClientsWithAccess = function (options) {
        var _this = this;
        return (0, exports.ClientsApiFp)(this.configuration).listClientsWithAccess(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Upon removal client won\'t be able to use user data anymore.
     * @summary Revoke client access
     * @param {ClientsApiRevokeClientAccessRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    ClientsApi.prototype.revokeClientAccess = function (requestParameters, options) {
        var _this = this;
        return (0, exports.ClientsApiFp)(this.configuration).revokeClientAccess(requestParameters.cid, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ClientsApi;
}(base_1.BaseAPI));
exports.ClientsApi = ClientsApi;
/**
 * ConsentsApi - axios parameter creator
 * @export
 */
var ConsentsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Consent id must be provided in the request body.  When a user grants consent which was already granted, it does not result in an error but it silently skipped instead.
         * @summary Grant privacy consent
         * @param {ConsentGrantRequest} [consentGrant]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantConsent: function (consentGrant_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([consentGrant_1], args_1, true), void 0, function (consentGrant, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/privacy/consents/grant";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication default required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "default", ["manage_consents"], configuration)];
                        case 1:
                            // authentication default required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(consentGrant, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * It is possible to provide time constraints using from and to query params.
         * @summary List privacy ledger events
         * @param {number} [from] Query events from timestamp (default 0)
         * @param {number} [to] Query events to timestamp (default current time)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPrivacyLedgerEvents: function (from_1, to_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([from_1, to_1], args_1, true), void 0, function (from, to, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/privacy/events";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication default required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "default", ["view_consents"], configuration)];
                        case 1:
                            // authentication default required
                            // oauth required
                            _a.sent();
                            if (from !== undefined) {
                                localVarQueryParameter['from'] = from;
                            }
                            if (to !== undefined) {
                                localVarQueryParameter['to'] = to;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * If you want to list only specific consents, provide consent identifiers in query params.
         * @summary List consents
         * @param {Array<string>} [consentId] optional identifiers of consents to be returned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserConsents: function (consentId_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([consentId_1], args_1, true), void 0, function (consentId, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/privacy/consents";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication default required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "default", ["view_consents"], configuration)];
                        case 1:
                            // authentication default required
                            // oauth required
                            _a.sent();
                            if (consentId) {
                                localVarQueryParameter['consent_id'] = consentId.join(base_1.COLLECTION_FORMATS.csv);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Returns any possible required consents that the app should ask the User about.  The response includes a list of consents (including the ones user already agreed to). Inclusion of the consents which the user already agreed to can be used to inform the user what he already agreed to.
         * @summary List consents by action
         * @param {string} action Consent action id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserConsentsByAction: function (action_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([action_1], args_1, true), void 0, function (action, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'action' is not null or undefined
                            (0, common_1.assertParamExists)('listUserConsentsByAction', 'action', action);
                            localVarPath = "/privacy/consents/{action}"
                                .replace("{".concat("action", "}"), encodeURIComponent(String(action)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication default required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "default", ["view_consents"], configuration)];
                        case 1:
                            // authentication default required
                            // oauth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This is a non-standardized PATCH request.  Allows to update multiple consents approval in one API call.  See ConsentGrantPatchRequest object for more information.
         * @summary Patch consent grants
         * @param {ConsentGrantPatchRequest} [consentGrantPatchRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchConsentGrants: function (consentGrantPatchRequest_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([consentGrantPatchRequest_1], args_1, true), void 0, function (consentGrantPatchRequest, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/privacy/consents";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication default required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "default", ["manage_consents"], configuration)];
                        case 1:
                            // authentication default required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(consentGrantPatchRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This API can be used to withdraw a consent which user previously gave.  Consent id must be provided in the request body.  When consent has the can_be_withdrawn flag set to false the API fails with an error saying that the consent cannot be revoked. This flag is useful for scenarios in which the application cannot function without the consent from a User.
         * @summary Revoke privacy consent
         * @param {ConsentGrantRequest} [consentGrant]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeConsent: function (consentGrant_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([consentGrant_1], args_1, true), void 0, function (consentGrant, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/privacy/consents/revoke";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication default required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "default", ["manage_consents"], configuration)];
                        case 1:
                            // authentication default required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(consentGrant, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.ConsentsApiAxiosParamCreator = ConsentsApiAxiosParamCreator;
/**
 * ConsentsApi - functional programming interface
 * @export
 */
var ConsentsApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.ConsentsApiAxiosParamCreator)(configuration);
    return {
        /**
         * Consent id must be provided in the request body.  When a user grants consent which was already granted, it does not result in an error but it silently skipped instead.
         * @summary Grant privacy consent
         * @param {ConsentGrantRequest} [consentGrant]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantConsent: function (consentGrant, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.grantConsent(consentGrant, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * It is possible to provide time constraints using from and to query params.
         * @summary List privacy ledger events
         * @param {number} [from] Query events from timestamp (default 0)
         * @param {number} [to] Query events to timestamp (default current time)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPrivacyLedgerEvents: function (from, to, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listPrivacyLedgerEvents(from, to, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * If you want to list only specific consents, provide consent identifiers in query params.
         * @summary List consents
         * @param {Array<string>} [consentId] optional identifiers of consents to be returned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserConsents: function (consentId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listUserConsents(consentId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Returns any possible required consents that the app should ask the User about.  The response includes a list of consents (including the ones user already agreed to). Inclusion of the consents which the user already agreed to can be used to inform the user what he already agreed to.
         * @summary List consents by action
         * @param {string} action Consent action id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserConsentsByAction: function (action, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listUserConsentsByAction(action, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This is a non-standardized PATCH request.  Allows to update multiple consents approval in one API call.  See ConsentGrantPatchRequest object for more information.
         * @summary Patch consent grants
         * @param {ConsentGrantPatchRequest} [consentGrantPatchRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchConsentGrants: function (consentGrantPatchRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.patchConsentGrants(consentGrantPatchRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This API can be used to withdraw a consent which user previously gave.  Consent id must be provided in the request body.  When consent has the can_be_withdrawn flag set to false the API fails with an error saying that the consent cannot be revoked. This flag is useful for scenarios in which the application cannot function without the consent from a User.
         * @summary Revoke privacy consent
         * @param {ConsentGrantRequest} [consentGrant]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeConsent: function (consentGrant, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.revokeConsent(consentGrant, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.ConsentsApiFp = ConsentsApiFp;
/**
 * ConsentsApi - factory interface
 * @export
 */
var ConsentsApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.ConsentsApiFp)(configuration);
    return {
        /**
         * Consent id must be provided in the request body.  When a user grants consent which was already granted, it does not result in an error but it silently skipped instead.
         * @summary Grant privacy consent
         * @param {ConsentGrantRequest} [consentGrant]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantConsent: function (consentGrant, options) {
            return localVarFp.grantConsent(consentGrant, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * It is possible to provide time constraints using from and to query params.
         * @summary List privacy ledger events
         * @param {number} [from] Query events from timestamp (default 0)
         * @param {number} [to] Query events to timestamp (default current time)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPrivacyLedgerEvents: function (from, to, options) {
            return localVarFp.listPrivacyLedgerEvents(from, to, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * If you want to list only specific consents, provide consent identifiers in query params.
         * @summary List consents
         * @param {Array<string>} [consentId] optional identifiers of consents to be returned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserConsents: function (consentId, options) {
            return localVarFp.listUserConsents(consentId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Returns any possible required consents that the app should ask the User about.  The response includes a list of consents (including the ones user already agreed to). Inclusion of the consents which the user already agreed to can be used to inform the user what he already agreed to.
         * @summary List consents by action
         * @param {string} action Consent action id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserConsentsByAction: function (action, options) {
            return localVarFp.listUserConsentsByAction(action, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This is a non-standardized PATCH request.  Allows to update multiple consents approval in one API call.  See ConsentGrantPatchRequest object for more information.
         * @summary Patch consent grants
         * @param {ConsentGrantPatchRequest} [consentGrantPatchRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchConsentGrants: function (consentGrantPatchRequest, options) {
            return localVarFp.patchConsentGrants(consentGrantPatchRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * This API can be used to withdraw a consent which user previously gave.  Consent id must be provided in the request body.  When consent has the can_be_withdrawn flag set to false the API fails with an error saying that the consent cannot be revoked. This flag is useful for scenarios in which the application cannot function without the consent from a User.
         * @summary Revoke privacy consent
         * @param {ConsentGrantRequest} [consentGrant]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeConsent: function (consentGrant, options) {
            return localVarFp.revokeConsent(consentGrant, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.ConsentsApiFactory = ConsentsApiFactory;
/**
 * ConsentsApi - object-oriented interface
 * @export
 * @class ConsentsApi
 * @extends {BaseAPI}
 */
var ConsentsApi = /** @class */ (function (_super) {
    __extends(ConsentsApi, _super);
    function ConsentsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Consent id must be provided in the request body.  When a user grants consent which was already granted, it does not result in an error but it silently skipped instead.
     * @summary Grant privacy consent
     * @param {ConsentsApiGrantConsentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentsApi
     */
    ConsentsApi.prototype.grantConsent = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return (0, exports.ConsentsApiFp)(this.configuration).grantConsent(requestParameters.consentGrant, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * It is possible to provide time constraints using from and to query params.
     * @summary List privacy ledger events
     * @param {ConsentsApiListPrivacyLedgerEventsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentsApi
     */
    ConsentsApi.prototype.listPrivacyLedgerEvents = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return (0, exports.ConsentsApiFp)(this.configuration).listPrivacyLedgerEvents(requestParameters.from, requestParameters.to, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * If you want to list only specific consents, provide consent identifiers in query params.
     * @summary List consents
     * @param {ConsentsApiListUserConsentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentsApi
     */
    ConsentsApi.prototype.listUserConsents = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return (0, exports.ConsentsApiFp)(this.configuration).listUserConsents(requestParameters.consentId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Returns any possible required consents that the app should ask the User about.  The response includes a list of consents (including the ones user already agreed to). Inclusion of the consents which the user already agreed to can be used to inform the user what he already agreed to.
     * @summary List consents by action
     * @param {ConsentsApiListUserConsentsByActionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentsApi
     */
    ConsentsApi.prototype.listUserConsentsByAction = function (requestParameters, options) {
        var _this = this;
        return (0, exports.ConsentsApiFp)(this.configuration).listUserConsentsByAction(requestParameters.action, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This is a non-standardized PATCH request.  Allows to update multiple consents approval in one API call.  See ConsentGrantPatchRequest object for more information.
     * @summary Patch consent grants
     * @param {ConsentsApiPatchConsentGrantsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentsApi
     */
    ConsentsApi.prototype.patchConsentGrants = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return (0, exports.ConsentsApiFp)(this.configuration).patchConsentGrants(requestParameters.consentGrantPatchRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This API can be used to withdraw a consent which user previously gave.  Consent id must be provided in the request body.  When consent has the can_be_withdrawn flag set to false the API fails with an error saying that the consent cannot be revoked. This flag is useful for scenarios in which the application cannot function without the consent from a User.
     * @summary Revoke privacy consent
     * @param {ConsentsApiRevokeConsentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentsApi
     */
    ConsentsApi.prototype.revokeConsent = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return (0, exports.ConsentsApiFp)(this.configuration).revokeConsent(requestParameters.consentGrant, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ConsentsApi;
}(base_1.BaseAPI));
exports.ConsentsApi = ConsentsApi;
/**
 * EnvironmentApi - axios parameter creator
 * @export
 */
var EnvironmentApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Get environment settings.  NOTE: this API may change any time, and it\'s meant for internal use only.
         * @summary Get environment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicEnvironment: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/environment";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication default required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "default", [], configuration)];
                        case 1:
                            // authentication default required
                            // oauth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.EnvironmentApiAxiosParamCreator = EnvironmentApiAxiosParamCreator;
/**
 * EnvironmentApi - functional programming interface
 * @export
 */
var EnvironmentApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.EnvironmentApiAxiosParamCreator)(configuration);
    return {
        /**
         * Get environment settings.  NOTE: this API may change any time, and it\'s meant for internal use only.
         * @summary Get environment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicEnvironment: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPublicEnvironment(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.EnvironmentApiFp = EnvironmentApiFp;
/**
 * EnvironmentApi - factory interface
 * @export
 */
var EnvironmentApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.EnvironmentApiFp)(configuration);
    return {
        /**
         * Get environment settings.  NOTE: this API may change any time, and it\'s meant for internal use only.
         * @summary Get environment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicEnvironment: function (options) {
            return localVarFp.getPublicEnvironment(options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.EnvironmentApiFactory = EnvironmentApiFactory;
/**
 * EnvironmentApi - object-oriented interface
 * @export
 * @class EnvironmentApi
 * @extends {BaseAPI}
 */
var EnvironmentApi = /** @class */ (function (_super) {
    __extends(EnvironmentApi, _super);
    function EnvironmentApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Get environment settings.  NOTE: this API may change any time, and it\'s meant for internal use only.
     * @summary Get environment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentApi
     */
    EnvironmentApi.prototype.getPublicEnvironment = function (options) {
        var _this = this;
        return (0, exports.EnvironmentApiFp)(this.configuration).getPublicEnvironment(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return EnvironmentApi;
}(base_1.BaseAPI));
exports.EnvironmentApi = EnvironmentApi;
/**
 * SessionsApi - axios parameter creator
 * @export
 */
var SessionsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Delete user session
         * @param {string} sessionID Session id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSession: function (sessionID_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([sessionID_1], args_1, true), void 0, function (sessionID, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'sessionID' is not null or undefined
                            (0, common_1.assertParamExists)('deleteSession', 'sessionID', sessionID);
                            localVarPath = "/sso/sessions/{sessionID}"
                                .replace("{".concat("sessionID", "}"), encodeURIComponent(String(sessionID)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication default required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "default", ["manage_sessions"], configuration)];
                        case 1:
                            // authentication default required
                            // oauth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * User sessions.
         * @summary Lists user sessions.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserSessions: function () {
            var args_1 = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args_1[_i] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/sso/sessions";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication default required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "default", ["view_sessions"], configuration)];
                        case 1:
                            // authentication default required
                            // oauth required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.SessionsApiAxiosParamCreator = SessionsApiAxiosParamCreator;
/**
 * SessionsApi - functional programming interface
 * @export
 */
var SessionsApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.SessionsApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Delete user session
         * @param {string} sessionID Session id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSession: function (sessionID, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteSession(sessionID, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * User sessions.
         * @summary Lists user sessions.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserSessions: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listUserSessions(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.SessionsApiFp = SessionsApiFp;
/**
 * SessionsApi - factory interface
 * @export
 */
var SessionsApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.SessionsApiFp)(configuration);
    return {
        /**
         *
         * @summary Delete user session
         * @param {string} sessionID Session id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSession: function (sessionID, options) {
            return localVarFp.deleteSession(sessionID, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * User sessions.
         * @summary Lists user sessions.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserSessions: function (options) {
            return localVarFp.listUserSessions(options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.SessionsApiFactory = SessionsApiFactory;
/**
 * SessionsApi - object-oriented interface
 * @export
 * @class SessionsApi
 * @extends {BaseAPI}
 */
var SessionsApi = /** @class */ (function (_super) {
    __extends(SessionsApi, _super);
    function SessionsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Delete user session
     * @param {SessionsApiDeleteSessionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    SessionsApi.prototype.deleteSession = function (requestParameters, options) {
        var _this = this;
        return (0, exports.SessionsApiFp)(this.configuration).deleteSession(requestParameters.sessionID, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * User sessions.
     * @summary Lists user sessions.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    SessionsApi.prototype.listUserSessions = function (options) {
        var _this = this;
        return (0, exports.SessionsApiFp)(this.configuration).listUserSessions(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return SessionsApi;
}(base_1.BaseAPI));
exports.SessionsApi = SessionsApi;
/**
 * TokensApi - axios parameter creator
 * @export
 */
var TokensApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Revokes tokens associated with the subject in the provided access token.  Tokens includes access, refresh tokens and sso sessions.  The optional `revoke_current_token` query parameter can be used to specify whether to revoke the current token as well (default: false).  If `revoke_current_token` is set to `true`, all access tokens, refresh tokens, and SSO session will also be revoked. If `revoke_current_token` is set to `false`, the current access token, corresponding refresh token, and SSO session will remain active.
         * @summary Revoke tokens
         * @param {boolean} [revokeCurrentToken] Optional flag to revoke current token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeTokens: function (revokeCurrentToken_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([revokeCurrentToken_1], args_1, true), void 0, function (revokeCurrentToken, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/tokens";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication default required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "default", [], configuration)];
                        case 1:
                            // authentication default required
                            // oauth required
                            _a.sent();
                            if (revokeCurrentToken !== undefined) {
                                localVarQueryParameter['revoke_current_token'] = revokeCurrentToken;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.TokensApiAxiosParamCreator = TokensApiAxiosParamCreator;
/**
 * TokensApi - functional programming interface
 * @export
 */
var TokensApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.TokensApiAxiosParamCreator)(configuration);
    return {
        /**
         * Revokes tokens associated with the subject in the provided access token.  Tokens includes access, refresh tokens and sso sessions.  The optional `revoke_current_token` query parameter can be used to specify whether to revoke the current token as well (default: false).  If `revoke_current_token` is set to `true`, all access tokens, refresh tokens, and SSO session will also be revoked. If `revoke_current_token` is set to `false`, the current access token, corresponding refresh token, and SSO session will remain active.
         * @summary Revoke tokens
         * @param {boolean} [revokeCurrentToken] Optional flag to revoke current token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeTokens: function (revokeCurrentToken, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.revokeTokens(revokeCurrentToken, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.TokensApiFp = TokensApiFp;
/**
 * TokensApi - factory interface
 * @export
 */
var TokensApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.TokensApiFp)(configuration);
    return {
        /**
         * Revokes tokens associated with the subject in the provided access token.  Tokens includes access, refresh tokens and sso sessions.  The optional `revoke_current_token` query parameter can be used to specify whether to revoke the current token as well (default: false).  If `revoke_current_token` is set to `true`, all access tokens, refresh tokens, and SSO session will also be revoked. If `revoke_current_token` is set to `false`, the current access token, corresponding refresh token, and SSO session will remain active.
         * @summary Revoke tokens
         * @param {boolean} [revokeCurrentToken] Optional flag to revoke current token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeTokens: function (revokeCurrentToken, options) {
            return localVarFp.revokeTokens(revokeCurrentToken, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.TokensApiFactory = TokensApiFactory;
/**
 * TokensApi - object-oriented interface
 * @export
 * @class TokensApi
 * @extends {BaseAPI}
 */
var TokensApi = /** @class */ (function (_super) {
    __extends(TokensApi, _super);
    function TokensApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Revokes tokens associated with the subject in the provided access token.  Tokens includes access, refresh tokens and sso sessions.  The optional `revoke_current_token` query parameter can be used to specify whether to revoke the current token as well (default: false).  If `revoke_current_token` is set to `true`, all access tokens, refresh tokens, and SSO session will also be revoked. If `revoke_current_token` is set to `false`, the current access token, corresponding refresh token, and SSO session will remain active.
     * @summary Revoke tokens
     * @param {TokensApiRevokeTokensRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokensApi
     */
    TokensApi.prototype.revokeTokens = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return (0, exports.TokensApiFp)(this.configuration).revokeTokens(requestParameters.revokeCurrentToken, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return TokensApi;
}(base_1.BaseAPI));
exports.TokensApi = TokensApi;
/**
 * TransientOtpApi - axios parameter creator
 * @export
 */
var TransientOtpApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Determine whether this OTP has been verified already.
         * @summary Get the One-Time Password (OTP) object
         * @param {string} otpID
         * @param {string} [state]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransientOTP: function (otpID_1, state_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([otpID_1, state_1], args_1, true), void 0, function (otpID, state, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'otpID' is not null or undefined
                            (0, common_1.assertParamExists)('getTransientOTP', 'otpID', otpID);
                            localVarPath = "/authn/otp/transient/{otpID}"
                                .replace("{".concat("otpID", "}"), encodeURIComponent(String(otpID)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication default required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "default", ["transient_otp"], configuration)];
                        case 1:
                            // authentication default required
                            // oauth required
                            _a.sent();
                            if (state !== undefined) {
                                localVarQueryParameter['state'] = state;
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Initiate a new transient OTP verification.
         * @summary Send a transient One-Time Password (OTP)
         * @param {TransientOTPRequest} [transientOTP]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateTransientOTP: function (transientOTP_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([transientOTP_1], args_1, true), void 0, function (transientOTP, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/authn/otp/transient";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication default required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "default", ["transient_otp"], configuration)];
                        case 1:
                            // authentication default required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(transientOTP, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Compare the OTP submitted by the user to the OTP that was sent.
         * @summary Verify the One-Time Password (OTP)
         * @param {string} otpID
         * @param {TransientOTPRequest} [transientOTP]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyTransientOTP: function (otpID_1, transientOTP_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([otpID_1, transientOTP_1], args_1, true), void 0, function (otpID, transientOTP, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'otpID' is not null or undefined
                            (0, common_1.assertParamExists)('verifyTransientOTP', 'otpID', otpID);
                            localVarPath = "/authn/otp/transient/{otpID}"
                                .replace("{".concat("otpID", "}"), encodeURIComponent(String(otpID)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication default required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "default", ["transient_otp"], configuration)];
                        case 1:
                            // authentication default required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(transientOTP, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.TransientOtpApiAxiosParamCreator = TransientOtpApiAxiosParamCreator;
/**
 * TransientOtpApi - functional programming interface
 * @export
 */
var TransientOtpApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.TransientOtpApiAxiosParamCreator)(configuration);
    return {
        /**
         * Determine whether this OTP has been verified already.
         * @summary Get the One-Time Password (OTP) object
         * @param {string} otpID
         * @param {string} [state]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransientOTP: function (otpID, state, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getTransientOTP(otpID, state, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Initiate a new transient OTP verification.
         * @summary Send a transient One-Time Password (OTP)
         * @param {TransientOTPRequest} [transientOTP]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateTransientOTP: function (transientOTP, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.initiateTransientOTP(transientOTP, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Compare the OTP submitted by the user to the OTP that was sent.
         * @summary Verify the One-Time Password (OTP)
         * @param {string} otpID
         * @param {TransientOTPRequest} [transientOTP]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyTransientOTP: function (otpID, transientOTP, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.verifyTransientOTP(otpID, transientOTP, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.TransientOtpApiFp = TransientOtpApiFp;
/**
 * TransientOtpApi - factory interface
 * @export
 */
var TransientOtpApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.TransientOtpApiFp)(configuration);
    return {
        /**
         * Determine whether this OTP has been verified already.
         * @summary Get the One-Time Password (OTP) object
         * @param {string} otpID
         * @param {string} [state]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransientOTP: function (otpID, state, options) {
            return localVarFp.getTransientOTP(otpID, state, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Initiate a new transient OTP verification.
         * @summary Send a transient One-Time Password (OTP)
         * @param {TransientOTPRequest} [transientOTP]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateTransientOTP: function (transientOTP, options) {
            return localVarFp.initiateTransientOTP(transientOTP, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Compare the OTP submitted by the user to the OTP that was sent.
         * @summary Verify the One-Time Password (OTP)
         * @param {string} otpID
         * @param {TransientOTPRequest} [transientOTP]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyTransientOTP: function (otpID, transientOTP, options) {
            return localVarFp.verifyTransientOTP(otpID, transientOTP, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.TransientOtpApiFactory = TransientOtpApiFactory;
/**
 * TransientOtpApi - object-oriented interface
 * @export
 * @class TransientOtpApi
 * @extends {BaseAPI}
 */
var TransientOtpApi = /** @class */ (function (_super) {
    __extends(TransientOtpApi, _super);
    function TransientOtpApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Determine whether this OTP has been verified already.
     * @summary Get the One-Time Password (OTP) object
     * @param {TransientOtpApiGetTransientOTPRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransientOtpApi
     */
    TransientOtpApi.prototype.getTransientOTP = function (requestParameters, options) {
        var _this = this;
        return (0, exports.TransientOtpApiFp)(this.configuration).getTransientOTP(requestParameters.otpID, requestParameters.state, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Initiate a new transient OTP verification.
     * @summary Send a transient One-Time Password (OTP)
     * @param {TransientOtpApiInitiateTransientOTPRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransientOtpApi
     */
    TransientOtpApi.prototype.initiateTransientOTP = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return (0, exports.TransientOtpApiFp)(this.configuration).initiateTransientOTP(requestParameters.transientOTP, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Compare the OTP submitted by the user to the OTP that was sent.
     * @summary Verify the One-Time Password (OTP)
     * @param {TransientOtpApiVerifyTransientOTPRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransientOtpApi
     */
    TransientOtpApi.prototype.verifyTransientOTP = function (requestParameters, options) {
        var _this = this;
        return (0, exports.TransientOtpApiFp)(this.configuration).verifyTransientOTP(requestParameters.otpID, requestParameters.transientOTP, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return TransientOtpApi;
}(base_1.BaseAPI));
exports.TransientOtpApi = TransientOtpApi;
